import React from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Form, Input } from '@components/rhf-mui5';
import {
  createFirebaseUser,
  fetchJMBGs,
  fetchKomitent,
  fetchKooperant,
  fetchKooperantiCodes,
  updateFirebaseUser,
} from '../api';
import AsyncAutocomplete from './AsyncAutocomplete';

const appClient = process.env.REACT_APP_CLIENT;

const select = {
  mikra: ({ email, telefon }) => ({
    email,
    mobilePhone: telefon?.[0] === '+' ? telefon : '+' + (telefon || '387'),
  }),
  partner: ({ email, telefonMob }) => ({
    email,
    mobilePhone: telefonMob?.[0] === '+' ? telefonMob : '+' + (telefonMob || '387'),
  }),
};

const checkPhone = (phone) => {
  if (phone) {
    return /^\+\d{3}(\d{2})\d{3}\d{3,4}$/.test(phone);
  }
  return true;
};

const checkPassword = (password) => {
  // if (password) return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/.test(password);
  if (password)
    return /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?![~@#\$%\^&\*_\-\+=`|{}:;!\.\?\"()\[\]]).{8,20})/.test(password);
  return true;
};

const userFormSchema = yup.object().shape({
  email: yup.string().typeError(`Email ne smije biti prazan`).email('Unesite ispravan email').nullable(),
  mobilePhone: yup
    .string()
    .required('Broj telefona je obavezno polje')
    .test('len', 'Format: +387XXXXXXXXX', (phone) => checkPhone(phone)),
  password: yup
    .string()
    .required('Password je obavezno polje')
    .test(
      'len',
      'Od 8 do 20 znakova, barem jedno veliko i malo slovo',
      (val) => checkPassword(val) && val.length >= 8 && val.length <= 20
    ),
  role: yup.string().required('Rola je obavezno polje'),
  username: yup.string().required('Username je obavezno polje'),
});

const UserFormModal = ({ item, method = 'patch', open, setOpen, isKomitent }) => {
  const role = isKomitent ? 'komitent' : 'kooperant';
  const isPatchMethod = method === 'patch';

  const [code, setCode] = React.useState('');
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync, isLoading } = useMutation(isPatchMethod ? updateFirebaseUser : createFirebaseUser);
  const { data: client, refetch: fetchClient } = useQuery(
    'client',
    () => {
      if (!code) return;
      const [searchIdentifier] = code.split(' - ');
      return isKomitent ? fetchKomitent(searchIdentifier) : fetchKooperant(searchIdentifier);
    },
    {
      refetchOnWindowFocus: false,
      enabled: code !== '',
      select: isKomitent ? select[appClient] : (data) => data,
    }
  );
  const { data: kooperanti } = useQuery('kooperanti-codes', () => fetchKooperantiCodes(''), {
    refetchOnWindowFocus: false,
    disabled: appClient === 'partner' || appClient === 'partnermkd',
  });
  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      mobilePhone: '+387',
      password: '',
      username: '',
      role: isKomitent ? 'komitent' : 'kooperant',
    },
    resolver: yupResolver(userFormSchema),
  });
  const handleSubmit = (values) => {
    const [jmbg] = code.split(' - ');
    try {
      mutateAsync({ firebaseUid: item?.firebaseUid, values: { ...values, code: jmbg } })
        .then((success) => {
          enqueueSnackbar(`Korisnik uspješno ${isPatchMethod ? 'ažuriran' : 'dodan'}!`, {
            variant: 'success',
          });
          queryClient.invalidateQueries(values.role === 'komitent' ? 'komitent' : 'kooperant');
          setOpen(null);
        })
        .catch((error) => {
          const message = error?.response?.data?.error?.message || 'Greška';

          enqueueSnackbar(message, {
            variant: 'error',
          });
        });
    } catch (error) {
      console.log('error');
    }
  };

  React.useEffect(() => {
    if (!item && code) fetchClient();
  }, [code]);
  React.useEffect(() => {
    if (!item && client) {
      methods.reset({ email: client.email || '', mobilePhone: client.mobilePhone, username: '', password: '', role });
    }
  }, [client]);

  React.useEffect(() => {
    queryClient.removeQueries('client');
    if (code === '')
      methods.reset({ email: '', password: '', username: '', mobilePhone: '+387', role }, { keepDirty: false });
    if (!open) {
      setCode('');
      methods.reset({ email: '', password: '', username: '', mobilePhone: '+387', role }, { keepDirty: false });
    }
    if (item) {
      setCode(item.code);
      methods.reset({ ...item }, { keepDirty: false });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(null)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll="paper"
      sx={{
        '.MuiFormControl-root': { height: 74, maxHeight: 74, boxSizing: 'border-box' },
      }}
    >
      <DialogTitle id="form-dialog-title">
        {isPatchMethod
          ? `Ažuriranje ${isKomitent ? 'komitenta' : 'kooperanta'}`
          : `Novi ${isKomitent ? 'komitent' : 'kooperant'}`}
      </DialogTitle>
      <DialogContent sx={{ pt: '24px !important', width: { xs: 'calc(100vw - 32px)', sm: 600 } }}>
        <Box mb={2}>
          {isKomitent && (
            <AsyncAutocomplete fetchFunction={fetchJMBGs} label="Komitent" value={code} setValue={setCode} />
          )}

          {!isKomitent && (
            <Autocomplete
              options={kooperanti}
              value={code}
              onChange={(_, newValue) => {
                setCode(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Kooperanti" />}
            />
          )}
          <Divider />
        </Box>
        <Form methods={methods} onSubmit={handleSubmit}>
          <Input name="username" label="Usenrame" xs={12} disabled={!client} />
          <Input name="email" label="Email" type="email" autoComplete="new-email" xs={12} disabled={!client} />
          <Input
            name="password"
            label="Password"
            type="password"
            autoComplete="new-password"
            xs={12}
            disabled={!client}
          />
          <Input
            name="mobilePhone"
            label="Broj mobilnog telefona"
            xs={12}
            defaultValue="+387"
            placeholder="+387XXXXXXXXX"
            disabled={!client}
          />

          <DialogActions>
            <Button type="submit" variant="contained" color="secondary">
              {isLoading ? <CircularProgress size={24} /> : 'Spasi'}
            </Button>
            <Button variant="text" onClick={() => setOpen(null)} color="primary">
              Zatvori
            </Button>
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default UserFormModal;
