/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import LoadingScreen from './LoadingScreen';
import { userState } from '../state/useUserContext';

export default function PrivateRoute({ children, ...rest }) {
  const [authState, setAuthState] = useRecoilState(userState);
  const childrenRef = useRef();

  useEffect(async () => {
    // Authenticate request
    let response;
    try {
      response = await axios.get(`${process.env.REACT_APP_IMIKRO_ENDPOINT}auth/authorize`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
    } catch (authError) {
      if (authError) {
        setAuthState({
          isAuthorized: false,
          access_token: null,
          user: null,
        });
      }
    }
  });

  if (!authState?.isAuthorized) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
      />
    );
  }
  return <span ref={childrenRef}>{children}</span>;
}
