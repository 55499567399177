import { Box, Typography } from '@material-ui/core';
import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import styled from 'styled-components';
import { statusColor } from '../utils/colors';
import Empty from './Empty';

const Container = styled.div`
  height: 365px;
`;

const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
  <g>
    <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
    <circle r={size / 5} strokeWidth={borderWidth} stroke={borderColor} fill={color} fillOpacity={0.35} />
  </g>
);

const ApplicationLineChart = ({ data }) => {
  if (!data || data.length === 0)
    return (
      <Box p={2}>
        <Empty title="Nema zahtjeva" subtitle="Nema podnesenih web zahtjeva u odabranom vremenskom periodu" />
      </Box>
    );

  return (
    <Container>
      <ResponsiveLine
        margin={{ top: 35, right: 85, bottom: 40, left: 45 }}
        enableSlices="x"
        colors={(line) => statusColor(line.code)}
        data={data.filter((lineData) => lineData.totalNumberOfApplications > 0)}
        curve="monotoneX"
        xScale={{
          type: 'time',
          format: '%d-%m-%Y',
          useUTC: false,
          precision: 'day',
        }}
        xFormat="time:%d-%m-%Y"
        yScale={{
          type: 'linear',
          stacked: false,
        }}
        axisLeft={{
          legend: 'Broj zahtjeva',
          tickValues: 6,
          legendPosition: 'middle',
          legendOffset: -40,
          tickPadding: 12,
        }}
        axisBottom={{
          format: (date) => moment(date).locale('ba').format('MMM D'),
          tickValues: 'every 14 days',
          legend: 'Dan',
          legendOffset: 28,
          legendPosition: 'middle',
        }}
        yFormat={(value) =>
          `${Number(value).toLocaleString('ba-BS', {
            minimumFractionDigits: 0,
          })}`
        }
        pointSymbol={CustomSymbol}
        pointSize={1}
        pointBorderWidth={1}
        pointBorderColor={{
          from: 'color',
          modifiers: [['darker', 0.3]],
        }}
        useMesh={true}
        enableArea={true}
        defs={[
          linearGradientDef('gradientA', [
            { offset: 0, color: 'inherit' },
            { offset: 100, color: 'inherit', opacity: 0 },
          ]),
        ]}
        fill={[{ match: '*', id: 'gradientA' }]}
        legends={[
          {
            translateX: 84,
            anchor: 'right',
            direction: 'column',
            justify: false,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        sliceTooltip={({ slice }) => {
          const date = slice.points[0].data.xFormatted;
          const totalApplications = slice.points.reduce((a, b) => a + b.data.y, 0);
          const totalAmount = slice.points.reduce(
            (a, b) => a + b.data.loanAplications.reduce((c, d) => c + d.amount, 0),
            0
          );
          const applications = slice.points.reduce((a, b) => [...a, ...b.data.loanAplications], []);
          return (
            <Box bgcolor="#fff" p={2} border="1px solid #ccc">
              <Typography variant="h6" color="inherit">
                Total: {totalApplications} zahtjeva ({totalAmount} BAM)
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                za dan {date}
              </Typography>
              {applications.map((application, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      color: statusColor(application.statusCode) || application.serieColor,
                      padding: '3px 0',
                    }}
                  >
                    <Typography variant="body2" color="inherit">
                      Zahtjev {application.applicationNumber}
                    </Typography>{' '}
                    <Typography variant="subtitle2">
                      {application.amount} BAM na {application.repaymentPeriod} mj.
                    </Typography>
                  </div>
                );
              })}
            </Box>
          );
        }}
      />
    </Container>
  );
};

export default ApplicationLineChart;
