import React from 'react';

import { Autocomplete, CircularProgress, TextField, InputAdornment } from '@mui/material';
import { BiSearchAlt } from 'react-icons/bi';
import throttle from 'lodash.throttle';

const getNoOptionsText = (input, loading) => {
  if (loading) return 'Pretražujem';
  if (input.length < 3) return 'Unesite bar 3 znaka';
  return 'Nema rezultata';
};

const AsyncAutocomplete = ({
  fetchFunction = () => null,
  label = 'Komitenti',
  value,
  setValue,
  textFieldVariant = 'outlined',
}) => {
  const [options, setOptions] = React.useState([]);
  const [input, setInput] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const fetchOptions = React.useMemo(
    () =>
      throttle(
        async (input) => {
          if (input.length < 3) {
            setOptions([]);
            setLoading(false);
            return;
          }
          setLoading(true);
          try {
            const options = await fetchFunction(input);

            if (options) setOptions(options);
            else setOptions([]);
          } catch (error) {
          } finally {
            setLoading(false);
          }
        },
        1000,
        { leading: true }
      ),
    []
  );

  const handleInputChange = (_, newInput) => {
    setLoading(true);
    fetchOptions(newInput);
    setInput(newInput);
  };

  return (
    <Autocomplete
      filterOptions={(x) => x}
      options={options}
      autoComplete
      filterSelectedOptions
      value={value}
      onChange={(_, newValue) => {
        setValue(newValue);
      }}
      onInputChange={handleInputChange}
      noOptionsText={getNoOptionsText(input, loading)}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          variant={textFieldVariant}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start" sx={{ svg: { color: 'primary.main' } }}>
                <BiSearchAlt size={28} />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AsyncAutocomplete;
