import { m } from 'framer-motion';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import Page from '../components/Page';
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { PageNotFoundIllustration } from '../assets';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Page404() {
  const { onHoverLeave } = useCollapseDrawer();

  useEffect(() => {
    onHoverLeave();
  }, []);

  return (
    <Page title="404 Page Not Found" sx={{ height: 1 }}>
      <RootStyle>
        <Container component={MotionContainer}>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <m.div variants={varBounce().in}>
              <Typography variant="h3" paragraph>
                Oops, stranica nije pronađena ili trenutno nije dostupna!
              </Typography>
            </m.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Nismo uspjeli pronaći stranicu koju tražite. Možda ste pogrešno unijeli URL? Provjerite sva slova i
              pokušajte opet.
            </Typography>

            <m.div variants={varBounce().in}>
              <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
            </m.div>

            <Button to="/" size="large" variant="contained" component={RouterLink}>
              Nazad na početak
            </Button>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
