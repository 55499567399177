import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Avatar, Box, Typography } from '@mui/material';
import styled from 'styled-components';

const Img = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`;

const Thumbnail = ({ imageSrc, filename }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box mr={1} p={1} bgcolor="#e6e6e6" borderRadius="8px">
        <Avatar onClick={handleClickOpen} alt="Image" variant="rounded" src={imageSrc} />
      </Box>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle disableTypography>
          <Typography variant="body1" align="center">
            {filename}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Img src={imageSrc} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Thumbnail;
