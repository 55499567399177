import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';
import { useState } from 'react';
import { statusColor } from '../utils/colors';

const ApplicationResponseTimesBar = ({ data, responseTime }) => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const handleClose = () => {
    setOpen(false);
    setIndex(0);
  };

  return (
    <>
      {!data || data?.length === 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center" pt={3}>
          <Typography variant="body2">Nema procesiranih web zahtjeva u odabranom vremenskom periodu</Typography>
        </Box>
      ) : (
        <Box height={148} mt={4.5}>
          <Dialog open={open} fullWidth>
            <DialogTitle>Zahtjevi sa vremenom odizva preko 5h</DialogTitle>
            <DialogContent>
              {(data[index].loanApplicationsWithResponseThanFiveHours || []).map(
                ({ id, status, createdAt, reactedAt, employee, statusCode }, index) => {
                  const durationMinutes = moment.duration(moment(reactedAt).diff(moment(createdAt))).asMinutes();
                  const duration = moment.duration(durationMinutes, 'minutes');
                  return (
                    <Box py={1} key={index}>
                      <div style={{ color: statusColor(statusCode) }}>
                        <Typography variant="body1" color="inherit">
                          Zahtjev WZ-{id}
                        </Typography>
                      </div>
                      <Box display="flex" justifyContent="space-between" flexWrap="nowrap">
                        <Typography variant="body1">
                          Datum kreiranja: <strong>{moment(createdAt).format('DD-MM-YYYY HH:mm')}</strong>
                        </Typography>
                        <Typography variant="body1">
                          Datum reagovanja: <strong>{moment(reactedAt).format('DD-MM-YYYY HH:mm')}</strong>
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between" flexWrap="nowrap">
                        <Typography variant="body1">
                          Vrijeme odziva:{' '}
                          <strong>
                            {duration.days()} {duration.days() === 1 ? 'dan' : 'dana'} {duration.hours()} h{' '}
                            {duration.minutes()} min {duration.seconds()} s
                          </strong>
                        </Typography>
                        <Typography variant="body1">
                          Radnik: <strong>{employee}</strong>
                        </Typography>
                      </Box>
                    </Box>
                  );
                }
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Zatvori</Button>
            </DialogActions>
          </Dialog>
          <ResponsiveBar
            data={data}
            keys={['responseTime', 'realResponse']}
            indexBy="weekNumber"
            onClick={({ index: barIndex }) => {
              setIndex(barIndex);
              setOpen(true);
            }}
            colorBy="id"
            colors={['#0086fa', '#00327e']}
            indexScale={{ type: 'band', round: true }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            label={null}
            enableGridY={false}
            enableGridX
            groupMode="grouped"
            borderRadius={5}
            valueScale={{ type: 'symlog' }}
            padding={0.35}
            innerPadding={1}
            motionStiffness={90}
            motionDamping={15}
            tooltip={({ value, data: responseTime }) => {
              const duration = moment.duration(Number(value), 'minutes');

              return (
                <Box bgcolor="#fff" p={2} border="1px solid #ccc">
                  <Typography variant="body1">
                    Početni dan:{' '}
                    <strong>
                      {moment(responseTime.year.toString())
                        .add(responseTime.weekNumber, 'weeks')
                        .startOf('isoweek')
                        .format('DD-MM-YYYY')}
                    </strong>
                  </Typography>
                  <Typography variant="body1">
                    Krajnji dan:{'  '}
                    <strong>
                      {moment(responseTime.year.toString())
                        .add(responseTime.weekNumber, 'weeks')
                        .endOf('isoweek')
                        .format('DD-MM-YYYY')}
                    </strong>
                  </Typography>
                  <Typography variant="body1">
                    Prosječno vrijeme odziva:{' '}
                    <strong>
                      {duration.days()} {duration.days() === 1 ? 'dan' : 'dana'} {duration.hours()} h{' '}
                      {duration.minutes()} min {duration.seconds()} s
                    </strong>
                  </Typography>
                  <Box pt={2}>
                    Od <strong>{responseTime.loanApplications.length}</strong>
                    {responseTime.loanApplications.length === 1 ? ' zahtjev' : ' zahtjeva'},{' '}
                    <strong>{responseTime.loanApplicationsWithResponseThanFiveHours.length}</strong>
                    {responseTime.loanApplicationsWithResponseThanFiveHours.length === 1
                      ? ' zahtjev ima'
                      : ' zahtjeva imaju'}{' '}
                    vrijeme odziva preko 5h.
                  </Box>
                </Box>
              );
            }}
          />
        </Box>
      )}
    </>
  );
};

export default ApplicationResponseTimesBar;
