import React from 'react';
import {
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlinePlus,
  AiOutlineMinus,
  AiOutlineArrowRight,
} from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import moment from 'moment';
import styled from 'styled-components';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  IconButton,
  Paper,
  Typography,
  Pagination,
} from '@mui/material';

export const FormHistoryModal = ({ open, setOpen, setPage, forms, page, returnHeaderForStep }) => {
  const getOldInputFromGivenFormId = (formId) => {
    let oldInput;
    for (let i = 0; i < Object.keys(forms[page].form).length; i++) {
      const input = forms[page].form[i].stepInputs.find(({ formId: inputFormId }) => inputFormId === formId);
      if (input) {
        oldInput = input;
        break;
      }
    }
    return oldInput;
  };

  const checkChanges = (oldInput, newInput) => {
    if (!oldInput || (oldInput && !oldInput.userPrivilege && newInput.userPrivilege))
      return <AiOutlinePlus color="green" />;
    if (oldInput && !newInput.userPrivilege) return <AiOutlineMinus color="red" />;
    if (oldInput.stepWeb === newInput.stepWeb) {
      if (newInput.stepOrderWeb > oldInput.stepOrderWeb) return <AiOutlineArrowDown color="red" />;
      else if (newInput.stepOrderWeb < oldInput.stepOrderWeb) return <AiOutlineArrowUp color="green" />;
    } else {
      return (
        <Box display="flex" gap={1} alignItems="center">
          {oldInput.stepWeb}.{' '}
          <AiOutlineArrowRight inline="true" color={newInput.stepWeb > oldInput.stepWeb ? 'red' : 'green'} />
          {newInput.stepWeb}.
        </Box>
      );
    }
    return '';
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          Historija forme
          <IconButton
            onClick={() => {
              setOpen(false);
              setPage(1);
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 17,
            }}
          >
            <IoMdClose />
          </IconButton>
        </Box>
      </DialogTitle>
      {Object.keys(forms).length <= 1 ? (
        <DialogContent>
          <Typography>Trenutno nema ranijih verzija forme</Typography>
        </DialogContent>
      ) : (
        <DialogContent sx={{ bgcolor: '#edf1f2', marginTop: '10px!important' }}>
          <Box sx={{ '& > *': { flex: '1 50%' } }} display="flex" mt={2} gap={2}>
            <Box display="flex" flexDirection="column">
              <Typography variant="h6">Verzija #{forms[page - 1].version}</Typography>
              <Typography variant="body1" color="secondary" fontWeight={300}>
                Datum kreiranja: {moment(forms[page - 1].createdAt).format('DD-MM-YYYY')}
              </Typography>
            </Box>
            {page !== Object.keys(forms).length && (
              <Box display="flex" flexDirection="column">
                <Typography variant="h6">Verzija #{forms[page].version}</Typography>
                <Typography variant="body1" color="secondary" fontWeight={300}>
                  Datum kreiranja: {moment(forms[page].createdAt).format('DD-MM-YYYY')}
                </Typography>
              </Box>
            )}
          </Box>
          {Object.entries(forms[page - 1].form).map(([_, { stepWeb, stepInputs }], headerIndex) => (
            <Box key={headerIndex} mb={headerIndex === 2 ? 0 : 4} mt={headerIndex === 0 ? 4 : 0}>
              <Box display="flex" flexWrap="nowrap" gap={2} sx={{ '& > *': { flex: '1 50%' } }}>
                <Paper elevation={1}>
                  <Box p={2}>{returnHeaderForStep(stepWeb)}</Box>
                  {stepInputs.map((input, inputIndex) => (
                    <Row key={inputIndex}>
                      <Box display="flex" gap={1} alignItems="center">
                        <Typography
                          variant="inherit"
                          fontWeight={600}
                          align="center"
                          sx={{
                            textDecoration: !input.userPrivilege ? 'line-through 3px red' : 'none',
                          }}
                        >
                          {input.label}
                        </Typography>
                      </Box>
                      <Box>{checkChanges(getOldInputFromGivenFormId(input.formId), input)}</Box>
                    </Row>
                  ))}
                </Paper>
                {page !== Object.keys(forms).length && (
                  <Paper elevation={1}>
                    <Box p={2}>{returnHeaderForStep(stepWeb)}</Box>
                    <Box>
                      {Object.entries(forms[page]?.form)?.[headerIndex]?.[1].stepInputs.map((input, inputIndex) => (
                        <Row key={inputIndex}>
                          <Box display="flex" gap={1} alignItems="center">
                            <Typography
                              sx={{
                                textDecoration: !input.userPrivilege ? 'line-through 3px red' : 'none',
                              }}
                              variant="inherit"
                              fontWeight={600}
                              align="center"
                            >
                              {input.label}
                            </Typography>
                          </Box>
                        </Row>
                      ))}
                    </Box>
                  </Paper>
                )}
              </Box>
            </Box>
          ))}
        </DialogContent>
      )}
      <DialogActions>
        <Pagination
          count={forms.length - 1 || 0}
          page={page}
          onChange={(_, page) => {
            if (page) setPage(page);
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

const Row = styled(Paper)`
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
`;

export default FormHistoryModal;
