import palette from '../theme/palette';

export const statusColor = (status) => {
  let color;
  if (status === 'pre' || status === 'prihv' || status === 'odobr') color = '#558811';
  else if (status === 'pro') color = '#00008B';
  else if (status === 'kk' || status === 'rk') color = '#008EED';
  else if (status === 'k1') color = palette?.light?.chart?.yellow[3];
  else if (status === 'k2') color = palette?.light?.chart?.yellow[2];
  else if (status === 'k3') color = palette?.light?.chart?.yellow[1];
  else if (status === 'k4') color = palette?.light?.chart?.yellow[0];
  else if (status === 'uo') color = '#004F82';
  else if (status === 'odb' || status === 'iodb') color = '#D52941';
  else if (status === 'rp') color = '#004F82';
  else if (status === 'ut' || status === 'uo') color = palette?.light?.secondary?.dark;
  else if (status === 'rk') color = '#9400D3';
  else if (status === 'kontk') color = '#9400D3';
  else if (status === 'odu') color = '#9400D3';
  else if (status === 'kompl') color = '#34ebde';
  else if (status === 'gu') color = '#7cd992';
  else color = '#687792';
  return color;
};
