import { Autocomplete, Checkbox, Form, Input, PhoneInput, Select } from '@components/rhf-mui5';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Badge, Box, CircularProgress, Fab, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { FiCamera, FiFolder, FiSave } from 'react-icons/fi';
import axios from '../api';
import { adminSchema } from '../validation';
import Documentation from './Documentation';
import EmployeeSelect from './EmployeeSelect';
import LocalCommunitySelect from './LocalCommunitySelect';
import LocalCommunitySelectByCity from './LocalCommunitySelectByCity';

const FormHeader = styled.div`
  margin-top: 52px;
  margin-bottom: 16px;
  position: relative;
`;

const documents = [
  { value: 101, label: 'Lična karta', description: 'Slika ili skenirana prednja i zadnja strane lične karte' },
  { value: 103, label: 'Potvrda o boravku', description: 'Slika ili skenirana potvrda o boravku (CIPS)' },
  {
    value: 105,
    label: 'Dokaz o primanjima',
    description: 'Slika ili skenirana platna lista ili izvod sa tekućeg računa',
  },
];
const uploadTypes = [
  { icon: <FiCamera />, label: 'Kamera', color: '#E4572E' },
  { icon: <FiFolder />, label: 'Sa uređaja', color: '#F4D35E' },
];

const ApplicationForm = ({
  id,
  formData,
  defaultValues,
  handleSubmit,
  registry,
  loading,
  refetchLoanData,
  disabledStatus,
  canEdit,
}) => {
  const [dirtyCount, setDirtyCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const methods = useForm({
    defaultValues: {},
    resolver: yupResolver(adminSchema),
    mode: 'onBlur',
  });
  const { reset, formState } = methods;

  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState([]);
  const [documentType, setDocumentType] = useState(101);

  const maxSize = 8097152;

  const onDropRejected = (fileRejections) => {
    enqueueSnackbar(`Maksimalna veličina dokumenta je 8MB`);
  };

  const onDrop = async (acceptedFiles) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append('files', file);
      formData.append('documentType', documentType);
      formData.append('loanApplicationId', id);
    });

    setIsUploading(true);
    const { data } = await axios.post(`/documents/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    setFiles((currentFiles) => [
      ...acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
      ...currentFiles,
    ]);
    setIsUploading(false);
    refetchLoanData();
  };

  const { acceptedFiles, rejectedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    minSize: 0,
    maxSize,
  });

  const isFileTooLarge = rejectedFiles?.length > 0 && rejectedFiles[0].size > maxSize;

  const description = documents.find((document) => document.value === documentType)?.description;

  const repaymentOptions = [
    { value: 'G', text: 'Uzimam gotovinu' },
    {
      value: 'ZG',
      text: 'Zatvorim određena kreditna zaduženja i uzimam dodatnu gotovinu',
    },
  ];

  useEffect(() => {
    setDirtyCount(Object.keys(formState.dirtyFields).length || 0);
    setErrorCount(Object.keys(formState.errors).length || 0);
    setIsDirty(formState.isDirty);
  }, [formState]);

  useEffect(() => {
    Object.keys(defaultValues).forEach((key) => {
      if (!defaultValues[key] && defaultValues[key] !== 0) defaultValues[key] = '';
    });
    reset({
      ...defaultValues,
      payoutType: defaultValues?.payoutType || '1',
      loanProduct: defaultValues?.loanProduct || '150',
    });
  }, [id, reset, defaultValues]);
  const timesRejected = defaultValues?.timesRejected || 0;

  let documentationStep = (
    <div>
      <Documentation documents={defaultValues?.documents || []} />
      <Box position="fixed" bottom={16} right={32}>
        {formState.errors.length}
        <Badge badgeContent={dirtyCount} color="primary">
          <Badge
            badgeContent={errorCount}
            color="error"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Fab color="primary" type="submit" disabled={!canEdit || !isDirty || loading}>
              {loading ? <CircularProgress color="primary" size={24} /> : <FiSave size={24} color="#fff" />}
            </Fab>
          </Badge>
        </Badge>
      </Box>
    </div>
  );
  return (
    <Form
      methods={methods}
      onSubmit={handleSubmit}
      inputProps={{
        disabled: !canEdit || !id || !!defaultValues?.erpKey || disabledStatus,
      }}
    >
      {!!timesRejected ? (
        <Alert variant="outlined" severity="warning" sx={{ mt: 3 }}>
          Klijent odbijen <b>{timesRejected}</b> puta u posljednjih 6 mjeseci!
        </Alert>
      ) : (
        <div />
      )}
      {formData?.map(({ type, label, name, width, options, step, editable }, index) => {
        switch (type) {
          case 'LOAN_INPUT':
            return (
              <Input
                label={label}
                name={name}
                type="number"
                lg={width}
                key={index}
                disabled={!canEdit || editable == 'N'}
              />
            );
          case 'INPUT':
            return <Input label={label} name={name} lg={width} key={index} disabled={!canEdit || editable == 'N'} />;
          case 'PHONE_INPUT':
            return (
              <PhoneInput label={label} name={name} xl={width} key={index} disabled={!canEdit || editable == 'N'} />
            );
          case 'CHECKBOX':
            return <Checkbox label={label} name={name} xl={width} key={index} disabled={!canEdit || editable == 'N'} />;
          case 'SELECT':
            return <Select name={name} label={label} options={registry[options] || []} key={index} />;
          case 'RADIO_GROUP':
            return <Select name={name} label={label} options={registry[options] || []} key={index} />;
          case 'AUTOCOMPLETE':
            return <Autocomplete name={name} label={label} freeSolo options={registry[options] || []} key={index} />;
          case 'LOCAL_COMMUNITY_SELECT':
            return (
              <LocalCommunitySelect
                defaultValue={defaultValues?.municipality || []}
                localCommunities={registry[options]}
                key={index}
              />
            );
          case 'LOCAL_COMMUNITY_SELECT_BY_CITY':
            return <LocalCommunitySelectByCity key={index} defaultValue={defaultValues.city} />;
          case 'EMPLOYEE_SELECT':
            return <EmployeeSelect key={index} defaultValue={defaultValues.office} />;
          case 'HEADING':
            return (
              <Typography variant="h4" sx={{ my: 4, pl: -5 }} key={index}>
                {step}. {label}
              </Typography>
            );
          default:
            return <div key={index}></div>;
        }
      })}
      {documentationStep}
    </Form>
  );
};

export default ApplicationForm;
