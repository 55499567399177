import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../state/useUserContext';
// @mui
import { Box, Button, CircularProgress, Container, FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
// components
import Page from '../components/Page';
// assets
import { Form, Input } from '@components/rhf-mui5';
import { useForm } from 'react-hook-form';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

const Login = () => {
  const location = useLocation();
  const [authState, setAuthState] = useRecoilState(userState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { from } = location.state || { from: { pathname: '/' } };

  const methods = useForm({
    defaultValues: {},
  });

  const onSubmit = async (data) => {
    let baseURL = process.env.REACT_APP_IMIKRO_ENDPOINT || 'http://localhost:5002/api';
    setLoading(true);
    axios
      .post(`${baseURL}auth/token/`, { ...data, grant_type: 'client_credentials' })
      .then(function (response) {
        if (response?.status === 200 && response?.data?.access_token) {
          localStorage.setItem('access_token', response?.data?.access_token);
          setAuthState({
            access_token: response?.data?.access_token,
            isAuthorized: true,
            user: response?.data?.user,
          });
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
        if (error?.response?.status === 401) setError('Prijava nije uspješna. Provjerite korisničko ime ili lozinku.');
        else if (error?.response?.status === 403) setError('Prijava neuspješna. Korisnički nalog više nije aktivan.');
        else setError(error?.message);
      });
  };

  if (authState?.isAuthorized) return <Navigate to={from?.pathname} />;
  return (
    <Page title="Login" sx={{ height: 1 }}>
      <RootStyle>
        <Container>
          <Box display="flex" justifyContent="center" mb={3}>
            <h1>Login</h1>
          </Box>
          <Form methods={methods} onSubmit={onSubmit} spacing={5}>
            <Input label="Korisničko ime" name="username" type="text" variant="filled" />
            <Input label="Password" name="password" type="password" variant="filled" />
            <Button
              size="large"
              color="primary"
              variant="contained"
              type="submit"
              style={{ width: '100%' }}
              disabled={loading}
            >
              {loading && <CircularProgress style={{ width: 22, height: 22, marginRight: 9 }} color="secondary" />}
              {loading ? 'Prijavljujem se...' : 'Prijavi se'}
            </Button>
            <FormHelperText error variant="filled">
              {error}
            </FormHelperText>
          </Form>
        </Container>
      </RootStyle>
    </Page>
  );
};

export default Login;
