import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => {
  const clientSuffix = process.env.REACT_APP_CLIENT === 'partnermkd' ? 'Partner MKD' : `${process.env.REACT_APP_CLIENT} MKF`;

  return (
    <>
      <Helmet>
        <title>{`${title} | ${clientSuffix}`}</title>
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  );
});


Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
