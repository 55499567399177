import React from 'react';
import { useWatch } from 'react-hook-form';
import { FormConnect, Select } from '@components/rhf-mui5';
import { useQuery } from 'react-query';
import { fetchEmployees } from '../api';

const EmployeeSelect = ({ control, defaultValue, ...inputProps }) => {
  const selectedOffice = useWatch({
    control,
    name: 'office',
    defaultValue,
  });

  const { data, isFetched } = useQuery(['empoloyees', selectedOffice], () => fetchEmployees(selectedOffice), {
    enabled: !!selectedOffice,
  });

  if (!data && !isFetched) return null;

  return (
    <FormConnect>
      <Select label="Kreditni promoter" name="loanOfficer" options={data} {...inputProps} />
    </FormConnect>
  );
};

export default EmployeeSelect;
