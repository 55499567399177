import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Table as MuiTable,
  Pagination,
  Tab,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tooltip
} from '@mui/material';
import { useEffect, useState } from 'react';
import { BsPencilSquare } from 'react-icons/bs';
import { useRecoilState } from 'recoil';

import { useQuery } from 'react-query';
import AsyncAutocomplete from 'src/components/AsyncAutocomplete';
import { fetchFirebaseUsernamesByCodeAndRole, fetchFirebaseUsersByRoleAndCode } from '../api';
import Page from '../components/Page';
import UserFormModal from '../components/UserFormModal';
import useSettings from '../hooks/useSettings';
import { userState } from '../state/useUserContext';

const isMikraClient = process.env.REACT_APP_CLIENT === 'mikra';

const TabPanel = ({ index, tab, children }) => {
  return (
    <TableBody
      role="tabpanel"
      hidden={tab !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {tab === index && children}
    </TableBody>
  );
};

const Row = ({ item, selectItem, canEdit }) => {
  const { username, email, mobilePhone, code } = item;
  return (
    <TableRow hover sx={{ height: 72, maxHeight: 72 }}>
      <TableCell sx={{ textAlign: 'center', height: 72, maxHeight: 72 }}>{username}</TableCell>
      <TableCell sx={{ textAlign: 'center', height: 72, maxHeight: 72 }}>{email || 'N/A'}</TableCell>
      <TableCell sx={{ textAlign: 'center', height: 72, maxHeight: 72 }}>{mobilePhone || 'N/A'}</TableCell>
      <TableCell sx={{ textAlign: 'center', height: 72, maxHeight: 72 }}>{code}</TableCell>
      <TableCell sx={{ textAlign: 'center', height: 72, maxHeight: 72 }}>
        <Tooltip title="Izmijeni">
          <IconButton onClick={() => selectItem(item)} disabled={!canEdit}>
            <BsPencilSquare />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};
const EmptyRow = () => (
  <TableRow>
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
  </TableRow>
);

const headers = [
  { header: 'Username', width: 0.2, textAlign: 'center' },
  { header: 'Email', width: 0.2, textAlign: 'center' },
  { header: 'Broj telefona', width: 0.2, textAlign: 'center' },
  { header: 'Šifra', width: 0.2, textAlign: 'center' },
  { header: 'Akcije', width: 0.2, textAlign: 'center' },
];
const TabTable = ({ role, selectItem, tab, tabIndex, canEdit, code = '' }) => {
  const [pageCount] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [emptyArray, setEmptyArray] = useState(new Array(10).fill(0));
  const { data, refetch, isLoading, isRefetching } = useQuery(
    role,
    () => fetchFirebaseUsersByRoleAndCode(role, code, page - 1),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { items, itemCount } = data || { items: [], itemCount: 0 };

  useEffect(() => {
    if (page === 1) {
      setTotalItems(itemCount || 0);
      const intTotalPages = Math.floor(itemCount / 10);

      if (itemCount <= 10) setTotalPages(1);
      else if (itemCount / 10 === intTotalPages) setTotalPages(intTotalPages);
      else setTotalPages(intTotalPages + 1);
    }
  }, [itemCount]);
  useEffect(() => {
    setEmptyArray(new Array(10 - items?.length || 0).fill(0));
  }, [items]);

  useEffect(() => {
    setPage(1);
  }, [code]);

  useEffect(() => {
    refetch();
  }, [page, code]);

  if (tab !== tabIndex) return null;
  return (
    <>
      <MuiTable aria-label="collapsible table" sx={{ tbody: { height: `calc(${pageCount} * 72px)` } }}>
        <TableHead>
          <TableRow>
            {headers.map(({ header, ...rest }) => (
              <TableCell key={header} sx={{ ...rest }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {(isLoading || isRefetching) && (
          <TableBody>
            <TableRow sx={{ position: 'relative' }}>
              <TableCell>
                <Box position="absolute" left="50%" top="50%">
                  <CircularProgress size={64} />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
        {!(isLoading || isRefetching) && (
          <TabPanel tab={tab} index={tabIndex}>
            {items.map((item) => (
              <Row key={item.firebaseUid} selectItem={selectItem} item={item} canEdit={canEdit} />
            ))}
            {emptyArray.map((_, i) => (
              <EmptyRow key={i} />
            ))}
          </TabPanel>
        )}
      </MuiTable>

      <Pagination
        count={totalPages}
        size="large"
        page={page}
        onChange={(_, value) => setPage(value)}
        sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}
      />
    </>
  );
};

const editRoles = ['DLS_ADMIN'];
const UsersManagement = () => {
  const { themeStretch } = useSettings();
  const [createUserOpen, setCreateUserOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [tab, setTab] = useState(0);

  const [searchValue, setSearchValue] = useState('');

  const [authState] = useRecoilState(userState);
  const canEdit = editRoles.includes(authState?.user?.role || 'DLS_ADMIN');

  return (
    <Page title="Korisnici">
      <Container maxWidth={themeStretch ? false : 'xl'} sx={{ pt: 5 }}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Box bgcolor="white" px={5} py={3} borderRadius={2}>
              <Box
                sx={{ mb: 5, borderBottom: 1, borderColor: 'divider' }}
                display="flex"
                justifyContent="space-between"
              >
                <Box maxWidth={300} display="flex">
                  <Tabs value={tab} onChange={(_, newTab) => setTab(newTab)} sx={{ display: 'flex' }}>
                    <Tab
                      label="Komitenti"
                      id={`simple-tab-${0}`}
                      aria-controls={`simple-tabpanel-${0}`}
                      sx={{ mr: '0px !important', px: 2 }}
                      value={0}
                    />
                    {isMikraClient && (
                      <Tab
                        label="Kooperanti"
                        id={`simple-tab-${1}`}
                        aria-controls={`simple-tabpanel-${1}`}
                        sx={{ px: 2 }}
                        value={1}
                      />
                    )}
                  </Tabs>
                </Box>

                <Box display="flex" gap={2} sx={{ '.MuiAutocomplete-root': { width: 400 } }} alignItems="center">
                  <Box>
                    {tab === 0 && (
                      <AsyncAutocomplete
                        textFieldVariant="standard"
                        value={searchValue}
                        setValue={setSearchValue}
                        fetchFunction={(searchValue) => fetchFirebaseUsernamesByCodeAndRole(searchValue, 'komitent')}
                        label="Pretraga"
                      />
                    )}
                    {tab === 1 && (
                      <AsyncAutocomplete
                        textFieldVariant="standard"
                        value={searchValue}
                        setValue={setSearchValue}
                        fetchFunction={(searchValue) => fetchFirebaseUsernamesByCodeAndRole(searchValue, 'kooperant')}
                        label="Pretraga"
                      />
                    )}
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setCreateUserOpen(true)}
                      disabled={!canEdit}
                      sx={{ minWidth: 140 }}
                    >
                      Novi {tab === 0 ? 'komitent' : 'kooperant'}
                    </Button>
                  </Box>
                </Box>
              </Box>

              <TabTable
                canEdit={canEdit}
                code={searchValue ? searchValue.split(' - ')[0] : ''}
                role="komitent"
                selectItem={setSelectedUser}
                tab={tab}
                tabIndex={0}
              />
              <TabTable
                canEdit={canEdit}
                code={searchValue ? searchValue.split(' - ')[0] : ''}
                role="kooperant"
                selectItem={setSelectedUser}
                tab={tab}
                tabIndex={1}
              />

              <UserFormModal
                method="post"
                open={createUserOpen}
                setOpen={setCreateUserOpen}
                isKomitent={tab === 0}
                canEdit={canEdit}
              />

              <UserFormModal
                item={selectedUser}
                method="patch"
                open={!!selectedUser}
                setOpen={setSelectedUser}
                isKomitent={tab === 0}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default UsersManagement;
