// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import { RecoilRoot } from 'recoil';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';
import localization from 'moment/locale/bs';

// components
//import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { QueryClient, QueryClientProvider } from 'react-query';

// --------------------------------------------------------------------

moment.updateLocale('bs', localization);

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <ThemeColorPresets>
          <RtlLayout>
            <MotionLazyContainer>
              <ProgressBarStyle />
              <ScrollToTop />
              <RecoilRoot>
                <SnackbarProvider maxSnack={3} autoHideDuration={10000}>
                  <Router />
                </SnackbarProvider>
              </RecoilRoot>
            </MotionLazyContainer>
          </RtlLayout>
        </ThemeColorPresets>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
