import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const userState = atom({
  key: 'firebaseUser',
  default: {
    isAuthorized: false,
    access_token: null,
    user: null,
  },
  effects_UNSTABLE: [persistAtom],
  dangerouslyAllowMutability: true,
});
