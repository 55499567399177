// const unusedFields = [
//   'additionalIncome',
//   'city',
//   'existingLoanDelinquency',
//   'householdSize',
//   'gracePeriod',
//   'monthlyExpenses',
//   'recommendedLoanProduct',
// ];

// const optionalFields = ['email', 'householdExistingLoanAmount', 'landlinePhone', 'remark'];

// const employeeFields = [
//   'loanProduct',
//   'loanPurpose',
//   'loanType',
//   'office',
//   'payoutType',
//   'recommendedAmount',
//   'recommendedGracePeriod',
//   'recommendedLoanPurpose',
//   'recommendedRepaymentPeriod',
// ];

const clientFields = [
  'address',
  'amount',
  'employer',
  'employmentStatus',
  'fathersName',
  'firstName',
  'jmbg',
  'lastName',
  'lk',
  'localCommunity',
  'martialStatus',
  'mobilePhone',
  'monthlyHouseholdExpenses',
  'monthlyHouseholdIncome',
  'monthlyIncome',
  'municipality',
  'repaymentPeriod',
  'urbanOrRural',
];

const percentCompleted = (loanApplication) => {
  if (!loanApplication) return 0;
  const mapped = Object.entries(loanApplication).map(([k, v]) => {
    return clientFields.includes(k) && v ? 1 : 0;
  });
  const filled = mapped.reduce((a, b) => a + b, 0) + loanApplication?.documents?.length;
  return (filled / (clientFields.length + 4)) * 100;
};

export default percentCompleted;
