// @mui

import styled from '@emotion/styled';
import { Box, Container, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
// hooks
import { FaPercentage } from 'react-icons/fa';
import { HiOutlineNewspaper } from 'react-icons/hi';
import { ImInfo } from 'react-icons/im';
import { RiTimeLine } from 'react-icons/ri';
import ApplicationLineChart from '../components/ApplicationLineChart';
import ApplicationResponseTimesBar from '../components/ApplicationResponseTimesBar';
import useSettings from '../hooks/useSettings';
import { userState } from '../state/useUserContext';
// components
import moment from 'moment';
import LazyLoad from 'react-lazyload';
import { useQuery } from 'react-query';
import { fetchLoanApplicationAggregateData, fetchLoanApplicationGraphData, getDisableActionstatuses } from '../api';
import ApplicationOverviewTable from '../components/ApplicationOverviewTable';
import ApplicationStepsOverview from '../components/ApplicationStepsOverview';
import LoadingScreen from '../components/LoadingScreen';
import Page from '../components/Page';
import ServerError from '../components/ServerError';
import generateGreeting from '../utils/generateGreeting';

// ----------------------------------------------------------------------

const GreetingWrapper = styled(Box)`
  ${({ theme }) => `
  & b {
    font-weight: 700;
  }

  & strong {
    font-weight: 700;
    font-size: 136%;
  }

  `}
`;

const SmallContainer = styled(Box)`
  flex-direction: column;
  min-height: 260px;
`;

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)`
  & .MuiTooltip-tooltip {
    color: rgba(0, 0, 0, 0.87);
    background-color: white;
    border: 1px solid #dadde9;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  }
`;

export default function Home() {
  const { themeStretch } = useSettings();
  const [authState, setAuthState] = useRecoilState(userState);

  const { data, error, isLoading } = useQuery(
    'loanApplicationGraphData',
    () =>
      fetchLoanApplicationGraphData({
        lineChartStartDate: moment().add(-6, 'months').startOf('day').format('YYYY-MM-DD'),
        barChartStartingDate: moment().add(-3, 'months').startOf('day').format('YYYY-MM-DD'),
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      staleTime: 1000 * 60 * 2,
      cacheTime: 1000 * 60 * 2,
    }
  );

  const {
    data: aggregateData,
    error: aggregateError,
    isLoading: numericIsLoading,
  } = useQuery('loanApplicationAggregateData', fetchLoanApplicationAggregateData);

  const { data: disableActionData } = useQuery(['disableActionsStatus'], () => getDisableActionstatuses());

  if (error || aggregateError) return <ServerError error={error || aggregateError} />;

  return (
    <Page title="Početna stranica">
      <Container maxWidth={themeStretch ? false : 'xl'} sx={{ pt: 5 }}>
        {isLoading || numericIsLoading ? (
          <LoadingScreen />
        ) : (
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Box display="flex">
                <GreetingWrapper
                  borderRight="1px solid lightgrey"
                  pr={5}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography variant="h3">
                    {generateGreeting()}, {authState?.user?.name || authState?.user?.email || 'Admin'}.
                  </Typography>
                  {!aggregateData?.today && (
                    <Typography variant="subtitle2">
                      Danas <b>{moment().format('DD-MM-YYYY')}</b> nije podnesen ni jedan zahtjev.
                    </Typography>
                  )}
                  {aggregateData?.today > 0 && (
                    <Typography variant="subtitle2" sx={{ mt: 1 }}>
                      Danas <b>{moment().format('DD-MM-YYYY')}</b> je podneseno {aggregateData?.today} zahtjeva.
                    </Typography>
                  )}
                </GreetingWrapper>
                <Box display="flex" my={1} ml={5} alignItems="center">
                  <Typography variant="h3">{aggregateData?.numberOfLoanApplications || 0}</Typography>
                  <Box ml={2} display="flex" flexDirection="column" width="200px" justifyContent="center">
                    <Typography variant="subtitle2" color="textPrimary">
                      <b>{aggregateData?.didNotReactYet || 0}</b> zahtjeva čekaju reakciju
                    </Typography>
                    <Typography variant="subtitle2" color="success.darker">
                      <b>{aggregateData?.employeeReacted || 0}</b> zahtjeva procesirana
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={4} lg={6} xs={12}>
              <SmallContainer
                display="flex"
                justifyContent="space-between"
                bgcolor="white"
                px={5}
                py={3}
                borderRadius={2}
                boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
              >
                <Box display="flex" alignItems="center" gap={1.8}>
                  <IconButton sx={{ bgcolor: '#cd63af', borderRadius: 1 }}>
                    <HiOutlineNewspaper color="white" size={15} />
                  </IconButton>
                  <Typography variant="h5">
                    <b>Zahtjevi</b>
                  </Typography>
                </Box>
                <ApplicationOverviewTable data={data?.overviewTableData || {}} />
              </SmallContainer>
            </Grid>
            <Grid item xl={4} lg={6} xs={12}>
              <SmallContainer
                bgcolor="white"
                px={5}
                pt={3}
                borderRadius={2}
                boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
              >
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center" gap={1.8}>
                    <IconButton sx={{ bgcolor: '#0086f1', borderRadius: 1 }}>
                      <RiTimeLine color="white" size={15} />
                    </IconButton>
                    <Typography variant="h5">
                      <b>Vremena odziva</b>
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <LightTooltip
                      title={
                        <Box p={1} gap={2} display="flex" flexDirection="column">
                          <Typography variant="body1">
                            <Box display="flex">
                              <Box display="flex" alignItems="top" marginRight={1}>
                                <svg width="30" height="20">
                                  <rect
                                    width="30"
                                    height="20"
                                    style={{ fill: '#0086fa', strokeWidth: 2, stroke: 'rgb(0,0,0)' }}
                                  />
                                </svg>
                              </Box>
                              Ovom bojom je označeno prosječno vrijeme odziva zahtjeva u toj sedmici, pri čemu zahtjeve
                              sa vremenom odziva preko 5h nisu uzete u obzir (zahtjevi koji su poslati preko vikenda,
                              zahtjevi koji su poslati poslije radnog vremena, itd.).
                            </Box>
                          </Typography>
                          <Typography variant="body1">
                            <Box display="flex">
                              <Box display="flex" alignItems="top" marginRight={1}>
                                <svg width="30" height="20">
                                  <rect
                                    width="30"
                                    height="20"
                                    style={{ fill: '#00327e', strokeWidth: 2, stroke: 'rgb(0,0,0)' }}
                                  />
                                </svg>
                              </Box>
                              Ovom bojom je označeno prosječno vrijeme odziva zahtjeva u toj sedmici, pri čemu zahtjeve
                              sa vremenom odziva preko 5h su uzete u obzir (zahtjevi koji su poslati preko vikenda,
                              zahtjevi koji su poslati poslije radnog vremena, itd.).
                            </Box>
                          </Typography>
                          <Box mt={1.5}>
                            <Typography variant="body1">
                              Klikom na grafikon možete vidjeti zahtjeve sa vremenom odziva preko 5h u toj sedmici.
                            </Typography>
                          </Box>
                        </Box>
                      }
                    >
                      <IconButton sx={{ borderRadius: 1 }}>
                        <ImInfo size={18} />
                      </IconButton>
                    </LightTooltip>
                  </Box>
                </Box>
                <LazyLoad>
                  <ApplicationResponseTimesBar data={data?.loanApplicationSplitByWeekAndResponseTimeThreshold || []} />
                </LazyLoad>
              </SmallContainer>
            </Grid>
            <Grid item xl={4} lg={12} xs={12}>
              <SmallContainer
                bgcolor="white"
                px={5}
                py={3}
                borderRadius={2}
                boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
              >
                <Box display="flex" alignItems="center" gap={1.8}>
                  <IconButton sx={{ bgcolor: '#72c662', borderRadius: 1 }}>
                    <FaPercentage color="white" size={15} />
                  </IconButton>
                  <Typography variant="h5">
                    <b>Stepen završenosti</b>
                  </Typography>
                </Box>
                <ApplicationStepsOverview
                  data={aggregateData?.steps || {}}
                  numberOfLoanApplications={aggregateData?.numberOfLoanApplications}
                />
              </SmallContainer>
            </Grid>
            <Grid item xs={12} sx={{ mt: -2 }}>
              <Box bgcolor="white" px={5} py={3} borderRadius={2} boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px">
                <Box display="flex" alignItems="center" gap={1.8}>
                  <Typography variant="h5">
                    <b>Pregled zahtjeva</b>
                  </Typography>
                </Box>
                <LazyLoad>
                  <ApplicationLineChart data={data.lineChartData || []} statuses={disableActionData || []} />
                </LazyLoad>
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}
