import React from 'react';
import { Grow, Paper, Popper, MenuItem, MenuList, Button, ButtonGroup } from '@mui/material';
import { FaAngleDown as ArrowDropDownIcon } from 'react-icons/fa';
import { FiLoader } from 'react-icons/fi';
import styled from '@emotion/styled';

const SplitButton = ({ options, canEdit, height }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleMenuItemClick = (_, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <ButtonGroup variant="outlined" ref={anchorRef} disabled={!canEdit} disableElevation sx={{ boxShadow: 'none' }}>
        <Button
          style={{
            marginRight: 0,
            maxHeight: height || 35,
            backgroundColor: options[selectedIndex]?.color,
            ...(height && { height }),
          }}
          sx={{ p: 2 }}
          variant={options[selectedIndex]?.variant || 'contained'}
          onClick={options[selectedIndex]?.action}
          disabled={!!options[selectedIndex]?.disabled}
          startIcon={options[selectedIndex]?.loading ? <FiLoader className="spinner" /> : options[selectedIndex]?.icon}
        >
          {options[selectedIndex]?.component || options[selectedIndex]?.name}
        </Button>
        <Button
          style={{ backgroundColor: options[selectedIndex]?.color, maxHeight: height || 35, ...(height && { height }) }}
          sx={{ p: 1 }}
          onClick={handleToggle}
          variant={options[selectedIndex]?.variantDropDown || 'contained'}
          disabled={!!options[selectedIndex]?.disabled}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <StyledPopper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <MenuList id="split-button-menu">
                {options.map((option, index) =>
                  option.included ? (
                    <MenuItem
                      key={option?.name}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option?.name}
                    </MenuItem>
                  ) : (
                    <></>
                  )
                )}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  );
};

const StyledPopper = styled(Popper)`
  z-index: 999;
`;

export default SplitButton;
