import React from 'react';
import styled from 'styled-components';
import { FormHelperText, Typography, Button } from '@mui/material';
import Lottie from './Lottie';
import serverErrorData from '../lotties/serverError.json';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  && > div {
    max-width: 80% !important;
    margin: auto;
  }

  .MuiFormHelperText-root {
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .MuiButton-root {
    min-width: 144px;
  }
`;

const ServerError = ({ error }) => {
  return (
    <Container>
      <Lottie animationData={serverErrorData} animate={true} />
      <Typography variant="h5">Došlo je do greške!</Typography>
      <FormHelperText error>{error?.response?.data?.error?.message || error?.message}</FormHelperText>
      <Button variant="contained" size="small" onClick={() => window.location.reload()}>
        Reload
      </Button>
    </Container>
  );
};

export default ServerError;
