import axios, { CancelToken } from 'axios';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { clientColumns, applicationColumns } from '../excel';
import { ICalendar } from 'datebook';

const handleError = (error) => {
  if (error?.response?.status === 401) {
    localStorage.removeItem('access_token');
    window.location.reload();
  }
  return Promise.reject(error);
};

const handleApiError = (error) => {
  if (error?.response?.status === 401) {
    localStorage.removeItem('access_token');
    window.location.reload();
  }
  throw error;
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:5000/api',
});

instance.interceptors.request.use(
  async (request) => {
    try {
      let access_token = localStorage.getItem('access_token');
      request.headers.Authorization = `Bearer ${access_token}`;
      return request;
    } catch (authError) {
      localStorage.removeItem('access_token');
      handleError(authError);
    }
  },
  (error) => Promise.reject(error)
);

export const erpAxios = axios.create({
  baseURL: process.env.REACT_APP_IMIKRO_ENDPOINT || 'http://localhost:5002/api/',
});

erpAxios.interceptors.request.use(
  async (request) => {
    try {
      let access_token = localStorage.getItem('access_token');
      request.headers.Authorization = `Bearer ${access_token}`;
      return request;
    } catch (authError) {
      localStorage.removeItem('access_token');
      handleError(authError);
    }
  },
  (error) => Promise.reject(error)
);

export default instance;

// ---------------------------------------------------------------------- //

export const fetchEmployees = async (office) => {
  const { data } = await erpAxios(`radnici/`, { params: { office } });
  return data;
};

export const fetchRegistry = async () => {
  const { data } = await erpAxios(`web-zahtjevi/registri/admin`);
  return data;
};

export const fetchAbandonmentReasons = async (id) => {
  const { data } = await erpAxios.get(`/statusOdbijen/razlozi`);
  return data;
};

export const fetchLocalCommunity = async (city) => {
  const { data } = await erpAxios.get(`/prostori/mjesne-zajednice`, { params: { city } });
  return data;
};

export const fetchKomitent = async (jmbg) => {
  const { data } = await erpAxios.get(`/komitenti/${jmbg}`);
  return data.id ? data : null;
};

export const fetchLoanInfo = async (komitent) => {
  const { data: loanData } = await erpAxios.get(`/loan/getActiveLoans/${komitent}`);
  const { data: codebtorData } = await erpAxios.get(`/loan/getActiveLoansAsCodebtor/${komitent}`);
  return { loanData, codebtorData };
};

export const fetchActivationTypes = async () => {
  const { data } = await erpAxios(`/gr-nacin-aktiviranja`);
  return data;
};

export const fetchLoanApplication = async (id) => {
  try {
    const { data } = await instance.get(`/loan-applications/${id}`);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchLoanApplications = async (params) => {
  try {
    const { data } = await instance.get('/loan-applications', { params });
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchLoanApplicationGraphData = async (params) => {
  try {
    const { data } = await instance.get('/loan-applications/statistics/graph-data', { params });
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchLoanApplicationAggregateData = async (params) => {
  try {
    const { data } = await instance.get('/loan-applications/statistics/aggregate-data', { params });
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchLoanApplicationResponseTime = async () => {
  try {
    const { data } = await instance.get('/loan-applications/responseTimes');
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchPostponedLoanApplications = async () => {
  try {
    const { data } = await instance.get(`/loan-applications/postponed/all`);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const forwardLoanApplication = async (params) => {
  const { id } = params;
  const { data } = await instance.get(`/loan-applications/${id}`);
  await erpAxios.post(`/web-zahtjevi/proslijedi`, { ...data, to: params });
  return instance.patch(`/loan-applications/${id}/forward`, { officerRemark: params.remark });
};

export const integrateLoanApplication = async (id) => {
  const { data } = await instance.get(`/loan-applications/${id}`);
  const { data: res } = await erpAxios.post(`/web-zahtjevi/prepis`, data);
  return instance.patch(`/loan-applications/${data.loanApplicationId}/transmit`, {
    erpKey: res.erpKey,
    erpKeyAlt: res.erpKeyAlt,
  });
};

export const patchLoanApplication = async ({ id, patch }) => {
  return instance.patch(`/loan-applications/${id}`, patch);
};

export const patchLoanApplicationReaction = async ({ id, patch }) => {
  return instance.patch(`/loan-applications/${id}/react`, patch);
};

export const patchLoanApplicationStatus = async ({ id, patch, loanData, download }) => {
  let icalendar;

  if (loanData)
    icalendar = new ICalendar({
      title: `Ostvariti kontakt sa: ${loanData.firstName} ${loanData.lastName}`,
      description: "Napomena za zahtjev u statusu 'Kontaktiraj kasnije'",
      start: moment(patch.postponed_until, 'DD.MM.YYYY. hh24:mi:ss').toDate(),
      end: moment(patch.postponed_until, 'DD.MM.YYYY. hh24:mi:ss').toDate(),
    });

  if (download && loanData) icalendar.download();

  return instance.patch(`/loan-applications/status/${id}`, patch);
};

export const postponeLoanApplication = async ({ id, patch, loanData, download }) => {
  let icalendar;

  if (loanData)
    icalendar = new ICalendar({
      title: `Ostvariti kontakt sa: ${loanData.firstName} ${loanData.lastName}`,
      description: "Napomena za zahtjev u statusu 'Kontaktiraj kasnije'",
      start: moment(patch.postponed_until, 'DD.MM.YYYY. hh24:mi:ss').toDate(),
      end: moment(patch.postponed_until, 'DD.MM.YYYY. hh24:mi:ss').toDate(),
    });

  if (download && loanData) icalendar.download();

  return instance.patch(`/loan-applications/${id}/postpone`, patch);
};

export const rejectLoanApplication = async ({ id, patch }) => {
  return instance.patch(`/loan-applications/${id}/reject`, patch);
};

export const saveLoanApplication = async (values) => {
  await instance.patch(`/clients/${values.clientId}`, values);
  await instance.patch(`/loan-applications/${values.loanApplicationId}`, values);
  if (values.creditAnalysisId) {
    await instance.patch(`/credit-analyses/${values.creditAnalysisId}`, values);
  } else {
    await instance.post(`/credit-analyses/`, { ...values, loanApplication: values.loanApplicationId });
  }
  if (process.env.REACT_APP_CLIENT === 'mikra') {
    for (let i = 0; i < values.documents.length; i++) {
      if (values.documents[i].documentType == 687 || values.documents[i].documentType == 692) {
        await instance.patch(`/documents/${values.documents[i].id}`, values);
      }
    }    
  }
};

export const createLoanApplicationExcelFile = async (params, setIsCreating) => {
  setIsCreating(true);

  await instance
    .post(
      '/loan-applications/generateExcelFile',
      { clientColumns, applicationColumns, ...params },
      { timeout: 0, responseType: 'blob' }
    )
    .then((response) => {
      setIsCreating(false);
      saveAs(response.data, `Kreditni_zahtjevi_${moment().format('DD_MM_YYYY_hh_mm')}.xlsx`);
    });
};

export const createLoanApplication = async (values) => {
  return instance.post(`/wizard/create/admin`, values);
};

export const getFormInformation = async (params) => {
  try {
    const { data } = await instance.get('/form', { params });
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getStatuses = async () => {
  try {
    const { data } = await instance.get('/status/');
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const batchUpdateForm = async ({ binds, newForm, version }) => {
  try {
    const { data } = await instance.patch('/form/batch-update', { binds, newForm, version });
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getEditFormInformation = async (params) => {
  try {
    const { data } = await instance.get('/form/edit', {
      params: {
        ...params,
        name: [
          'repaymentPeriod',
          'recommendedLoanProduct',
          'gracePeriod',
          'office',
          'recommendedLoanPurpose',
          'payoutType',
          'loanType',
          'recommendedRepaymentPeriod',
          'recommendedAmount',
          'recommendedGracePeriod',
          'annuity',
          'loanProduct',
          'employeePromoter',
        ],
      },
    });
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getFormHistory = async () => {
  try {
    const { data } = await instance.get('/form/history');
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getDisableActionstatuses = async () => {
  try {
    const { data } = await instance.get('/status/disable-actions');
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const createFirebaseUser = async ({ values }) => {
  try {
    const { data, status } = await instance.post('/users', values);

    return status === 200;
  } catch (error) {
    handleApiError(error);
  }
};
export const updateFirebaseUser = async ({ firebaseUid, values }) => {
  try {
    const { data, status } = await instance.patch(`/users/${firebaseUid}`, values);
    return status === 200;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchFirebaseUsersByRoleAndCode = async (role, code, page) => {
  try {
    const {
      data: { users = [], total = 0 },
    } = await instance.get('/users', { params: { role, code, page } });

    return { items: users, itemCount: total };
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchFirebaseUsernamesByCodeAndRole = async (code, role) => {
  try {
    const {
      data: { users },
    } = await instance.get('/users/username', { params: { code, role } });
    return users.map(({ username, code }) => `${code} - ${username}`);
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchActivityData = async (params) => {
  try {
    const { data } = await instance.get('/activity/all', { params });
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchActivityList = async (offset, limit) => {
  try {
    const { data } = await instance.get('/activity/list', { params: { offset, limit } });
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchActivityStatistics = async (params) => {
  try {
    const { data } = await instance.get('/activity/statistics', { params });
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchJMBGs = async (jmbg) => {
  try {
    const {
      data: { komitenti },
    } = await erpAxios.get('komitenti/jmbgs', { params: { jmbg } });

    return komitenti.map(({ jmbg, naziv }) => `${jmbg} - ${naziv}`);
  } catch (error) {
    handleApiError(error);
  }
};
export const fetchKooperant = async (code) => {
  try {
    const {
      data: { kooperant },
    } = await erpAxios.get(`kooperanti/${code}`);

    return kooperant;
  } catch (error) {
    handleApiError(error);
  }
};
export const fetchKooperantiCodes = async (name) => {
  try {
    const {
      data: { kooperanti },
    } = await erpAxios.get('kooperanti/codes', { params: { name } });

    return kooperanti.map(({ name, code }) => `${code}  - ${name}`);
  } catch (error) {
    handleApiError(error);
  }
};

export const patchFormActions = async (values) => {
  return await instance.patch('form/actions', values);
};

export const getFormEmailActions = async () => {
  try {
    const { data } = await instance.get('form/action/emails');
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const deleteEmailAction = async (emailType) => {
  await instance.delete(`form/email-actions/${emailType}`);
};

// ---------------------------------------------------------------------- //
