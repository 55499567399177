import moment from 'moment';

const generateGreeting = () => {
  const currentHour = parseInt(moment().format('HH'));
  if (currentHour >= 2 && currentHour <= 10) {
    return 'Dobro jutro';
  } else if (currentHour > 10 && currentHour <= 18) {
    return 'Dobar dan';
  } else {
    return 'Dobro večer';
  }
};

export default generateGreeting;
