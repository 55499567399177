import React from 'react';
import styled from 'styled-components';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';

const StyledTableRow = styled(TableRow)`
  .MuiTableCell {
    color: red;
  }
`;

const StyledTableCell = styled(TableCell)`
  font-size: 0.75rem;
  font-weight: 600;
  padding-left: 0 !important;

  &&.MuiTableCell-head {
    font-weight: 400;
    font-size: 0.75rem;
  }
`;

const ApplicationOverviewTable = ({ data }) => {
  return (
    <TableContainer component={Box} minHeight={150} mt={3}>
      <Table aria-label="Pregled zahtjeva po periodu" size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Period</StyledTableCell>
            <StyledTableCell align="center">Novi</StyledTableCell>
            <StyledTableCell align="center">Kompletiran</StyledTableCell>
            <StyledTableCell align="center">Prepisan</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow key={1}>
            <StyledTableCell component="th" scope="row">
              <Typography variant="caption">
                <b>Sedmica</b>
              </Typography>
            </StyledTableCell>
            <StyledTableCell align="center">{data?.weekIncomplete || 0}</StyledTableCell>
            <StyledTableCell align="center">{data?.weekCompleted || 0}</StyledTableCell>
            <StyledTableCell align="center">{data?.weekTransmitted || 0}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key={2}>
            <StyledTableCell component="th" scope="row">
              <Typography variant="caption">
                <b>Mjesec</b>
              </Typography>
            </StyledTableCell>
            <StyledTableCell align="center">{data?.monthIncomplete || 0}</StyledTableCell>
            <StyledTableCell align="center">{data?.monthCompleted || 0}</StyledTableCell>
            <StyledTableCell align="center">{data?.monthTransmitted || 0}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key={3}>
            <StyledTableCell component="th" scope="row">
              <Typography variant="caption">
                <b>Kvartal</b>
              </Typography>
            </StyledTableCell>
            <StyledTableCell align="center">{data?.quarterIncomplete || 0}</StyledTableCell>
            <StyledTableCell align="center">{data?.quarterCompleted || 0}</StyledTableCell>
            <StyledTableCell align="center">{data?.quarterTransmitted || 0}</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ApplicationOverviewTable;
