import React from 'react';
import { useWatch } from 'react-hook-form';
import { fetchLocalCommunity } from '../api';
import { FormConnect, Select } from '@components/rhf-mui5';
import { useQuery } from 'react-query';

const LocalCommunitySelectByCity = ({ control, defaultValue }) => {
  const selectedCity = useWatch({
    control,
    name: 'city',
    defaultValue,
  });

  const { data } = useQuery(['empoloyees', selectedCity], () => fetchLocalCommunity(selectedCity), {
    enabled: !!selectedCity,
  });
  let options = data || [];

  return (
    <FormConnect>
      <Select label="Mjesna zajednica" name="localCommunity" options={options} />
    </FormConnect>
  );
};

export default LocalCommunitySelectByCity;
