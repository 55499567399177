import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import Lottie from './Lottie';
import empty from '../lotties/empty.svg';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 26px;
  && > div {
    max-width: 100% !important;
  }
`;

const Empty = ({ title, subtitle, noLottie, leftAligned }) => {
  return (
    <Container>
      <Box display="flex" justifyContent="center">
        {!noLottie && <img src={empty} width={200} />}
      </Box>
      <Box
        mt={leftAligned ? 0 : 5}
        display="flex"
        flexDirection="column"
        justifyContent={leftAligned ? 'flex-start' : 'center'}
        textAlign={leftAligned ? 'left' : 'center'}
      >
        <Typography variant="h6" gutterBottom>
          <b>{title}</b>
        </Typography>
        <Typography variant="subtitle2">{subtitle}</Typography>
      </Box>
    </Container>
  );
};

export default Empty;
