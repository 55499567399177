import React, { useState } from 'react';
import { FaIdCard, FaMap, FaPhone, FaUser, FaUserCircle } from 'react-icons/fa';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import {
  Avatar,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Popover,
  Typography,
  useTheme,
} from '@mui/material';
import { fetchKomitent, fetchLoanInfo } from '../api';
import layoutConfig from '../layouts/layout-config.js';

const AvatarOutline = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme?.palette?.primary?.main};
  color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  box-shadow: 0 1.4px 4.7px rgba(0, 0, 0, 0.028), 0 4px 12.9px rgba(0, 0, 0, 0.04), 0 9.6px 31.1px rgba(0, 0, 0, 0.052),
    0 32px 103px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  height: 48px;
  width: 48px;

  .MuiAvatar-root {
    color: inherit;
    height: 60px;
    width: 60px;
  }
`;

const AvatarItem = styled(Avatar)`
  background: ${({ theme, error }) => (error ? theme?.palette?.error?.main : theme?.palette?.success?.light)};
`;

const StyledProgress = styled(CircularProgress)`
  .MuiCircularProgress-bottom {
    background-color: gray !important;
  }
`;

const ClientProfile = ({ client, percent = 0 }) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [config, setConfig] = useState(layoutConfig);

  const { data: existingClient, isLoading } = useQuery(['komitent', client?.jmbg], () => fetchKomitent(client?.jmbg), {
    enabled: !!client?.jmbg,
  });

  const { data: loanInfo, isLoading: isLoadingLoans } = useQuery(
    ['loanInfo'],
    () => fetchLoanInfo(existingClient?.komitent),
    {
      enabled: !!existingClient?.komitent && config.zahtjevi.uzimanjeAktivnihKredita === 'enabled',
    }
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'info-o-klijentu' : undefined;

  const color = existingClient ? palette.info.main : palette.warning.main;

  const data = [
    {
      existingValue: `${existingClient?.ime} (${existingClient?.otac}) ${existingClient?.prezime}`,
      newValue: `${client?.firstName} (${client?.fathersName}) ${client?.lastName}`,
      label: 'Naziv',
      icon: <FaUser />,
    },
    {
      existingValue: existingClient?.lk,
      newValue: client?.lk,
      label: 'Broj lične karte',
      icon: <FaIdCard />,
    },
    {
      existingValue: `${existingClient?.adresa}, ${existingClient?.opcinaStanovanja} (${existingClient?.mjestoStanovanja})`,
      newValue: `${client?.address}, ${client?.municipality} (${existingClient?.localCommunity})`,
      label: 'Adresa, Opština (Mjesna zajednica)',
      icon: <FaMap />,
    },
    {
      existingValue: `${existingClient?.telefonMob}/${existingClient?.telefonFix || 'Nema'}`,
      newValue: `${client?.mobilePhone}/${client?.landlinePhone || 'Nema'}`,
      label: 'Mobilni/fiksni tel.',
      icon: <FaPhone />,
    },
  ];

  const hasMismatch = data.some(({ existingValue, newValue }) => existingValue !== newValue);

  return (
    <>
      <Box onClick={handleClick} textAlign="center" style={{ cursor: 'pointer' }} ml={2}>
        <Box position="relative" display="inline-flex">
          <StyledProgress variant="determinate" value={percent} size={90} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <FaUserCircle size={72} color={isLoading ? '#e6e6e6' : color} />
          </Box>
        </Box>
        {(isLoading || isLoadingLoans) && (
          <Typography variant="h5" color="textSecondary">
            Provjera klijenta
          </Typography>
        )}
      </Box>
      {existingClient && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          disableScrollLock
        >
          <Box border="1px solid #efefef" display="flex" flexDirection="column" p={2}>
            <Typography variant="h5">{existingClient?.status || 'Nepoznat status'}</Typography>
            <Box display="flex" alignItems="center" mb={1} mt={2} p={1}>
              <AvatarOutline>
                <Typography variant="h5" color="inherit">
                  {existingClient?.ime[0]}
                  {existingClient?.prezime[0]}
                </Typography>
              </AvatarOutline>
              <Box ml={1}>
                <Typography variant="h5">
                  {existingClient?.ime} {existingClient?.prezime}
                </Typography>
                <Typography variant="subtitle1">JMBG: {existingClient?.maticniBroj}</Typography>
                <Typography variant="subtitle2">
                  Ima aktivan kredit: {loanInfo?.loanData[0]?.brojac > 0 ? 'Da' : 'Ne'}
                </Typography>
                <Typography variant="subtitle2">
                  Sudužnik na aktivnom kreditu: {loanInfo?.codebtorData[0]?.brojac > 0 ? 'Da' : 'Ne'}
                </Typography>
              </Box>
            </Box>
            <List
              dense
              disablePadding
              subheader={
                <ListSubheader disableSticky>
                  Dodatne informacije
                  {hasMismatch ? (
                    <Box maxWidth={245}>
                      <Typography variant="subtitle2">
                        Postoje razlike u podacima postojećeg klijenta i podacima unesenim kroz web zahtjev.
                      </Typography>
                    </Box>
                  ) : (
                    ''
                  )}
                </ListSubheader>
              }
            >
              {data.map(({ existingValue, newValue, label, icon }) => {
                const matches = existingValue === newValue;
                return (
                  <ListItem key={label}>
                    <ListItemAvatar>
                      <AvatarItem variant="rounded" error={matches ? 0 : 1}>
                        {icon}
                      </AvatarItem>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          {existingValue}
                          {!matches && (
                            <Typography variant="body2" style={{ textDecoration: 'line-through' }} color="error">
                              {newValue}
                            </Typography>
                          )}
                        </>
                      }
                      secondary={label}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Popover>
      )}
    </>
  );
};

export default ClientProfile;
