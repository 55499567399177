import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  Popper,
  Fade,
  TextField,
  Badge,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';
import { Form, Select, Checkbox } from '@components/rhf-mui5';
import { useForm, Controller } from 'react-hook-form';
import { FiX } from 'react-icons/fi';
import { GrFilter } from 'react-icons/gr';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import styled from 'styled-components';
import moment from 'moment';

const arrayForWatch = ['startingDate', 'endingDate', 'loanProduct', 'office'];

const ApplicationListFilter = ({
  status,
  setStatus,
  registry,
  startingDate,
  endingDate,
  officeInput,
  loanProductInput,
  labeledStatuses,
  setStartingDate,
  setEndingDate,
  setOfficeInput,
  setLoanProductInput,
  erpKeyRadio,
  setErpKeyRadio,
  repaymentLoanRadio,
  setRepaymentLoanRadio,
}) => {
  const [anchorForFilter, setAnchorForFilter] = useState(null);
  const handleClickForFilter = (event) => {
    setAnchorForFilter(anchorForFilter ? null : event.currentTarget);
    resetFilter(defaultValues);
  };

  const handleSubmitFilter = (values) => {
    const { startingDate, endingDate, loanProduct, office, erpKey, repaymentLoan, ...valuesWithout } = values;
    const checked = Object.keys(valuesWithout).filter((key) => values[key]);

    setStatus(checked);
    setStartingDate(
      startingDate && moment(startingDate).isValid() ? moment(startingDate).format('YYYY-MM-DD').toString() : null
    );
    setEndingDate(
      endingDate && moment(endingDate).isValid() ? moment(endingDate).format('YYYY-MM-DD').toString() : null
    );
    setLoanProductInput(loanProduct);
    setOfficeInput(office);
    setAnchorForFilter(null);
    setErpKeyRadio(erpKey);
    setRepaymentLoanRadio(repaymentLoan ? 'ZG' : null);
  };

  const removeInputTagValue = () => {
    setStartingDate(null);
    setEndingDate(null);
    setOfficeInput('');
    setLoanProductInput('');
    methodFilter.setValue('startingDate', null);
    methodFilter.setValue('endingDate', null);
    methodFilter.setValue('office', '');
    methodFilter.setValue('loanProduct', '');
  };

  const closeFilter = () => {
    setAnchorForFilter(null);
    resetFilter(defaultValues);
  };

  const uncheckStatuses = () => {
    const { startingDate, endingDate, loanProduct, office, erpKey, repaymentLoan, ...statuses } = methodFilter.getValues();
    Object.keys(statuses).forEach((key) => {
      defaultValues[key] = false;
    });
    defaultValues['pre'] = false;
    arrayForWatch.forEach((item) => (defaultValues[item] = methodFilter.getValues(item)));
    defaultValues['erpKey'] = methodFilter.getValues('erpKey');
    defaultValues['repaymentLoan'] = methodFilter.getValues('repaymentLoan');
    resetFilter(defaultValues);
  };

  const checkAllStatuses = () => {
    const { startingDate, endingDate, loanProduct, office, erpKey, repaymentLoan, ...statuses } = methodFilter.getValues();
    Object.keys(statuses).forEach((key) => {
      defaultValues[key] = true;
    });
    defaultValues['pre'] = true;
    arrayForWatch.forEach((item) => (defaultValues[item] = methodFilter.getValues(item)));
    defaultValues['erpKey'] = methodFilter.getValues('erpKey');
    defaultValues['repaymentLoan'] = methodFilter.getValues('repaymentLoan');
    resetFilter(defaultValues);
  };

  const defaultValues = {
    startingDate,
    endingDate,
    office: officeInput,
    loanProduct: loanProductInput,
    erpKey: erpKeyRadio,
    repaymentLoan: repaymentLoanRadio,
  };
  status.forEach((item) => (defaultValues[item] = true));
  const methodFilter = useForm({
    defaultValues,
    mode: 'onBlur',
  });

  const { reset: resetFilter } = methodFilter;

  const openFilter = Boolean(anchorForFilter);
  const idFilter = openFilter ? 'transitions-popper' : undefined;

  const isNotEmpty = (array) => {
    return methodFilter.watch(array).some((value) => value);
  };

  const filterStyle = {
    color: isNotEmpty(arrayForWatch) ? '#047cc4' : 'grey',
    filter: isNotEmpty(arrayForWatch) ? 'drop-shadow(0px 0px 3px #047cc4)' : '',
  };

  return (
    <>
      <BadgeSmall color="error" badgeContent={status.filter((status) => status !== 'pre').length}>
        <GrFilterPointer onClick={handleClickForFilter} size={24} style={filterStyle} />
      </BadgeSmall>
      <Popper
        id={idFilter}
        open={openFilter}
        anchorEl={anchorForFilter}
        placement="bottom"
        transition
        style={{ zIndex: 120 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper elevation={4}>
              <ResposniveBox p={2}>
                <Box flexDirection="row" display="flex" justifyContent="space-between">
                  <Typography gutterBottom variant="h4">
                    Filteri
                  </Typography>
                  <FiX onClick={closeFilter} style={{ cursor: 'pointer' }} />
                </Box>
                <Form methods={methodFilter} onSubmit={handleSubmitFilter}>
                  <ResponsiveFormField>
                    <Box flexDirection="column" display="flex" justifyContent="flex-end">
                      <Typography variant="h6">Datum kreiranja</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" paddingTop="0!important">
                      <Controller
                        name="startingDate"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <MobileDatePicker
                                label="Početni dan"
                                inputFormat="dd-MM-yyyy"
                                mask="__-__-____"
                                value={value}
                                onChange={onChange}
                                renderInput={(params) => <TextField {...params} style={{ paddingRight: '35px' }} />}
                                onAccept={(value) => {
                                  const lastDate = methodFilter.getValues('endingDate');
                                  if (lastDate && value && moment(value).clone().isAfter(moment(lastDate).clone())) {
                                    onChange(lastDate);
                                    methodFilter.setValue('endingDate', value);
                                  }
                                }}
                              />
                            </LocalizationProvider>
                          );
                        }}
                      />
                      <Controller
                        name="endingDate"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <MobileDatePicker
                                label="Krajnji dan"
                                inputFormat="dd-MM-yyyy"
                                mask="__-__-____"
                                value={value}
                                onChange={onChange}
                                onAccept={(value) => {
                                  const firstDate = methodFilter.getValues('startingDate');
                                  if (firstDate && value && moment(value).clone().isBefore(moment(firstDate).clone())) {
                                    onChange(firstDate);
                                    methodFilter.setValue('startingDate', value);
                                  }
                                }}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          );
                        }}
                      />
                    </Box>
                  </ResponsiveFormField>
                  <ResponsiveFormField>
                    <ResponsiveSelect
                      style={{ marginBottom: '10px' }}
                      label="Kancelarija"
                      name="office"
                      options={registry.offices}
                    />
                    <ResponsiveSelect label="Kreditni proizvod" name="loanProduct" options={registry.loanProducts} />
                  </ResponsiveFormField>
                  <Box flexDirection="column" display="flex" flexWrap="wrap">
                    <Box flexDirection="row" display="flex" style={{ alignItems: 'center' }}>
                      <Typography variant="h6">Status</Typography>
                      <Button style={{ color: 'red', fontWeight: 1 }} size="small" onClick={uncheckStatuses}>
                        Očisti
                      </Button>
                      <Button style={{ color: 'green', fontWeight: 1 }} size="small" onClick={checkAllStatuses}>
                        Odaberi sve
                      </Button>
                    </Box>
                    <Grid container spacing={2}>
                      {labeledStatuses?.map(
                        (loanApplicationStatus) =>
                          loanApplicationStatus.code !== 'pre' && (
                            <Grid key={loanApplicationStatus.id} item xs={6} sm={4} md={3}>
                              <Checkbox label={loanApplicationStatus.name} name={loanApplicationStatus.code} />{' '}
                            </Grid>
                          )
                      )}
                    </Grid>
                  </Box>
                  <Controller
                    name="erpKey"
                    render={({ field }) => (
                      <RadioGroup row {...field}>
                        <FormControlLabel value="all" control={<StyledRadio />} label="Svi" />
                        <FormControlLabel value="transmitted" control={<StyledRadio />} label="Prepisani" />
                        <FormControlLabel value="untransmitted" control={<StyledRadio />} label="Neprepisani" />
                      </RadioGroup>
                    )}
                  />
                  {/* <Controller
                    name="repaymentLoan"
                    render={({ field }) => (
                      <Checkbox {...field} label="Zatvorim određena kreditna zaduženja i uzimam dodatnu gotovinu" />
                    )}
                  /> */}

                  <Box display="flex" justifyContent="space-between">
                    {isNotEmpty(arrayForWatch) ? (
                      <Button onClick={removeInputTagValue} style={{ color: 'red' }}>
                        Očisti polja
                      </Button>
                    ) : (
                      <div />
                    )}
                    <Button type="submit">Filtriraj</Button>
                  </Box>
                </Form>
              </ResposniveBox>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

const ResposniveBox = styled(Box)`
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: lightgrey;
    background-clip: content-box;
    border: 3px solid transparent;
    border-radius: 9px;
  }
  max-width: 620px;
`;

const GrFilterPointer = styled(GrFilter)`
  cursor: pointer;
`;

const BadgeSmall = styled(Badge)`
  & .MuiBadge-badge {
    min-width: 15px !important;
    width: 18px !important;
    height: 15px !important;
  }
`;

const ResponsiveFormField = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 630px) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
`;

const ResponsiveSelect = styled(Select)`
  width: 40%;

  @media (max-width: 630px) {
    width: 100%;
  }
`;

const StyledRadio = styled(Radio)`
  ${({ theme }) => `
  
`}
`;

export default ApplicationListFilter;
