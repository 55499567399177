import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';
import { useQuery } from 'react-query';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const logo = (
    <Box sx={{ width: process.env.REACT_APP_CLIENT === 'mikra' ? 50 : 60, height: 40, ...sx }}>
      <img
        src={`/icons/${process.env.REACT_APP_CLIENT}_logo_desktop.${
          process.env.REACT_APP_CLIENT === 'mikra' ? 'png' : 'jpeg'
        }`}
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
