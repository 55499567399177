module.exports = {
  zahtjevi: {
    generisanjeIzvjestaja: 'enabled',
    odgadjanjeZahtjeva: 'enabled',
    prepisivanjeZahtjeva: 'enabled',
    odbijanjeZahtjeva: 'enabled',
    proslijedjivanjeZahtjeva: process.env.REACT_APP_CLIENT === 'mikra' ? 'disabled' : 'enabled',
    manualnoKreiranjeZahtjeva: process.env.REACT_APP_CLIENT === 'mikra' ? 'enabled' : 'disabled',
    uzimanjeAktivnihKredita: process.env.REACT_APP_CLIENT === 'mikra' ? 'disabled' : 'enabled',
    prikazFormaAktivnosti: process.env.REACT_APP_CLIENT === 'mikra' ? 'disabled' : 'enabled',
  },
};
