import { React } from 'react';
import { ResponsiveContainer, Tooltip, Bar, BarChart, AreaChart, Area, PieChart, Pie, Cell, ComposedChart } from 'recharts';
import { Typography, useTheme, Box, IconButton } from '@mui/material';
import { AiOutlineRise, AiOutlineFall, AiOutlineAreaChart, AiOutlineBarChart, AiOutlinePieChart } from 'react-icons/ai';

import { statusColor } from '../utils/colors';

import GeneralTooltip from '@mui/material/Tooltip';


export default function AnalyticsStatsCard(props) {
  const { palette } = useTheme();
  const { data, title, displayData, changeRate, color, colorDark, type, hasChartData} = props;

  const placeholderChart = (statsType) => (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <GeneralTooltip title="Ne postoje podaci za prikaz">
        <IconButton>
          {statsType == '1' ? (
            <AiOutlineAreaChart size="60px" style={{ color: '#d8d9d9' }} />
          ) : statsType == '2' ? (
            <AiOutlineBarChart size="60px" style={{ color: '#d8d9d9' }} />
          ) : (
            <AiOutlinePieChart size="60px" style={{ color: '#d8d9d9' }} />
          )}
        </IconButton>
      </GeneralTooltip>
    </Box>
  );

  const changeIcon = (changeRate) => (
    <Box
      mr={1}
      borderRadius={50}
      height={16}
      width={16}
      bgcolor={
        changeRate == 0
          ? palette.warning.secondary
          : changeRate < 0
          ? palette.error.secondary
          : palette.success.secondary
      }
    >
      {changeRate > 0 ? (
        <AiOutlineRise style={{ color: palette.success.main, size: '3px', marginRight: '1px' }} />
      ) : changeRate < 0 ? (
        <AiOutlineFall style={{ color: palette.error.main, size: '3px', marginRight: '1px' }} />
      ) : (
        <></>
      )}
    </Box>
  );

  let name;
  let parameterName;

  if (data?.length) name = Object.keys(data?.[0])?.[0];
  else name = 'name';

  if (data?.length) parameterName = Object.keys(data?.[0])?.[1];
  else parameterName = 'parameterName';

  function CustomTooltip({ payload, active }) {
    if (active) {
      return (
        <Box
          width={140}
          component="div"
          p={1}
          style={{ border: '1px solid white', borderRadius: '5px', backgroundColor: 'white' }}
        >
          {type == '1' && (
            <Typography variant="subtitle2" color="inherit">
              Broj prijava sa ispunjenim korakom <b>{payload[0]?.payload?.stepName}</b> iznosi{' '}
              <b>{payload[0]?.value}</b>
            </Typography>
          )}

          {type == '2' && (
            <Typography variant="subtitle2" color="inherit">
              Prosječno vrijeme popunjavanja za <b>{payload[0]?.payload?.stepName}</b> iznosi{' '}
              <b>
                {Math.floor(payload[0]?.value / 60).toFixed(0)}m{' '}
                {(payload[0]?.value - Math.floor(payload[0]?.value / 60) * 60).toFixed(0)}s
              </b>
            </Typography>
          )}

          {type == '3' && (
            <Typography variant="subtitle2" color="inherit">
              Broj zahtjeva sa statusom <b>{payload[0]?.payload?.statusName}</b> iznosi <b>{payload[0]?.value}</b>
            </Typography>
          )}
        </Box>
      );
    }

    return null;
  }
  return (
    <>
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle2" style={{ fontWeight: '700' }} gutterBottom>
          {title}
        </Typography>
        <Box pl={1}>
          <Typography variant="h3" gutterBottom>
            {displayData}
          </Typography>
        </Box>
        <Box display="flex" flexWrap="wrap">
          {type == '2' ? (
            <>
              <Typography variant="body2">Prosječno vrijeme za sve zahtjeve:</Typography>
              <Typography variant="subtitle2" style={{ fontWeight: '700', marginLeft: '3px' }}>
                {changeRate}{' '}
              </Typography>
            </>
          ) : (
            <>
              {changeRate == 'Nema podataka' && (
                <Typography variant="body2">Nema podataka od prošlog mjeseca</Typography>
              )}
              {changeRate != 'Nema podataka' && (
                <>
                  {changeRate != 0 && changeIcon(changeRate)}
                  {changeRate == 0 && (
                    <Typography style={{ marginLeft: '3px' }} variant="body2">
                      Nema promjene od prošlog mjeseca
                    </Typography>
                  )}
                  {changeRate != 0 && (
                    <>
                      <Typography variant="subtitle2" style={{ fontWeight: '700' }}>
                        {changeRate}% {'  '}
                      </Typography>
                      {changeRate > 0 && (
                        <Typography style={{ marginLeft: '3px' }} variant="body2">
                          povećanje od prošlog mjeseca
                        </Typography>
                      )}
                      {changeRate < 0 && (
                        <Typography style={{ marginLeft: '3px' }} variant="body2">
                          smanjenje od prošlog mjeseca
                        </Typography>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </Box>

      <Box width="20%" display="flex" justifyContent="center">
        {!hasChartData && placeholderChart(type)}
        <ResponsiveContainer>
          {hasChartData && type == '1' ? (
            <ComposedChart width='95%' height='80%' data={data}>
              <Tooltip content={<CustomTooltip />} position={{ y: -100 }} />
              <Area type="monotone" dataKey={parameterName} stroke={color} fill={color} />
              <Bar dataKey={parameterName} fill={colorDark || 'white'} />
            </ComposedChart>

          ) : hasChartData && type == '2' ? (
            <BarChart width='95%' height='80%' data={data}>
              <Bar dataKey={parameterName} fill={color} />
              <Tooltip content={<CustomTooltip />} position={{ y: -100 }} />
            </BarChart>
          ) : hasChartData && type == '3' ? (
            <PieChart>
              <Pie
                data={data}
                cx={30}
                cy={50}
                innerRadius={20}
                outerRadius={30}
                paddingAngle={2}
                dataKey="statusPercentage"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      statusColor(entry.statusCode)
                    }
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} position={{ y: -80 }}/>
            </PieChart>
          ) : (
            <></>
          )}
        </ResponsiveContainer>
      </Box>
    </>
  );
}
