// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  homepage: getIcon('ic_homepage'),
  applications: getIcon('ic_applications'),
  analytics: getIcon('ic_analytics'),
  edit: getIcon('ic_dashboard'),
  user: getIcon('ic_user'),
};

const sidebarConfig = [
  {
    subheader: 'Navigacija',
    items: [
      { title: 'Početna stranica', path: '/home', icon: ICONS.homepage },
      { title: 'Zahtjevi', path: '/applications', icon: ICONS.applications },
      { title: 'Analitike', path: '/analytics', icon: ICONS.analytics },
      { title: 'Uređivanje forme', path: '/form', icon: ICONS.edit },
      { title: 'Korisnici', path: '/users', icon: ICONS.user },
    ],
  },
];

export default sidebarConfig;
