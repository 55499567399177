import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { AiOutlinePhone } from 'react-icons/ai';
import { FcCancel, FcCheckmark } from 'react-icons/fc';
import {
  IoIosArrowDropleft,
  IoIosArrowDropright,
  IoMdArrowDropdown,
  IoMdCheckbox,
  IoMdRadioButtonOn,
} from 'react-icons/io';
import { MdModeEdit } from 'react-icons/md';
import { RiText } from 'react-icons/ri';
import { TiSortNumerically } from 'react-icons/ti';
import { useMutation, useQuery } from 'react-query';
import { batchUpdateForm, fetchRegistry, getEditFormInformation, getFormEmailActions, getFormHistory } from '../api';
import FormActionModal from '../components/FormActionModal';
import FormEmailsActionModal from '../components/FormEmailsActionModal';
import FormHistoryModal from '../components/FormHistoryModal';
import LoadingScreen from '../components/LoadingScreen';
import Page from '../components/Page';
import ServerError from '../components/ServerError';
import SplitButton from '../components/SplitButton';
import useSettings from '../hooks/useSettings';
import layoutConfig from '../layouts/layout-config';

const headerStep = (text) => (
  <FormHeader>
    <Typography variant="h4" fontSize="1.35rem!important">
      {text}
    </Typography>
  </FormHeader>
);

const returnHeaderForStep = (step) => {
  switch (step) {
    case 1:
      return headerStep('1. Informacije o kreditu');
    case 2:
      return headerStep('2. Osnovne informacije o komitentu');
    default:
      return headerStep('3. Kreditna analiza');
  }
};

const returnIconForInputType = (type, theme) => {
  switch (type) {
    case 'INPUT_NUMBER':
    case 'LOAN_INPUT':
      return <TiSortNumerically size={23} inline="true" color={theme.palette.secondary.main} />;
    case 'INPUT':
      return <RiText size={17} inline="true" color={theme.palette.secondary.main} />;
    case 'CHECKBOX':
      return <IoMdCheckbox size={20} inline="true" color={theme.palette.secondary.main} />;
    case 'PHONE_INPUT':
      return <AiOutlinePhone size={20} inline="true" color={theme.palette.secondary.main} />;
    case 'RADIO_GROUP':
      return <IoMdRadioButtonOn size={20} inline="true" color={theme.palette.secondary.main} />;
    default:
      return <IoMdArrowDropdown size={20} inline="true" color={theme.palette.secondary.main} />;
  }
};

const mustExistInputs = ['amount', 'repaymentPeriod', 'firstName', 'lastName', 'loanPurpose', 'address', 'mobilePhone'];

export default function DraggableForm() {
  const [deletedInputs, setDeletedInputs] = useState([]);
  const [currentlyDeletedInputs, setCurrentlyDeletedInputs] = useState([]);
  const [userForm, setUserForm] = useState([]);
  const [actions, setActions] = useState({});
  const [loading, setLoading] = useState(false);
  const [collapse, setCollapsed] = useState(false);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [inputLabelChanging, setInputLabelChanging] = useState({ stepIndex: null, stepInputIndex: null });
  const [openEmailAction, setOpenEmailAction] = useState(false);
  const [actionModalOpen, setActionModalOpen] = useState(false);

  const theme = useTheme();

  const { themeStretch } = useSettings();

  const { mutateAsync } = useMutation(batchUpdateForm);

  const { enqueueSnackbar } = useSnackbar();

  const {
    data,
    error: formError,
    isLoading: isLoadingForm,
    refetch: refetchForm,
    isRefetching: isRefetchingForm,
  } = useQuery('edit-form', getEditFormInformation, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const inputs = [];
      const headings = [];
      const nonActiveInputs = [];
      data.forEach((formData) => {
        if (formData.type === 'HEADING' && formData.stepWeb !== 4) headings.push({ ...formData });
        else if (formData.stepWeb !== 4 && formData.active !== 'N')
          inputs.push({
            ...formData,
            label: formData.name === 'amount' ? `${formData.label}, Broj rata` : formData.label,
          });
        if (formData.stepWeb !== 4 && (formData.active === 'N' || !formData.userPrivilege))
          nonActiveInputs.push({ ...formData });
      });

      headings.forEach((heading) => {
        const stepInputs = [];
        inputs.forEach((input) => {
          if (heading.stepWeb === input.stepWeb && input.userPrivilege) stepInputs.push(input);
        });
        heading.stepInputs = stepInputs;
      });

      setUserForm(headings);
      setDeletedInputs(nonActiveInputs);
    },
  });

  const {
    data: forms,
    error: formHistoryError,
    isLoading: isLoadingFormHistory,
    isRefetching,
    refetch,
  } = useQuery('form-history', getFormHistory, {
    refetchOnWindowFocus: false,
  });

  const { data: registry, isLoading: isLoadingRegistry } = useQuery('registry', fetchRegistry, {
    refetchOnWindowFocus: false,
  });

  const {
    data: emailActions,
    isLoading: isLoadingEmailActions,
    refetch: refetchEmailActions,
    error: emailActionsError,
    isRefetching: isRefetchingEmailActions,
  } = useQuery('emailActions', getFormEmailActions, {
    enabled: layoutConfig.zahtjevi.prikazFormaAktivnosti === 'enabled',
    refetchOnWindowFocus: false,
  });

  const handleSubmit = async () => {
    setLoading(true);
    const newForm = [...userForm];
    const newFormWithDeletedInputs = newForm.map((heading) => ({
      ...heading,
      stepInputs: [
        ...heading.stepInputs,
        ...currentlyDeletedInputs.filter(({ stepWeb: inputStep }) => heading.stepWeb === inputStep),
      ],
    }));

    await mutateAsync({
      binds: actions,
      newForm: JSON.stringify(Object.assign({}, newFormWithDeletedInputs)),
      version: forms.length + 1,
    })
      .then((message) => {
        enqueueSnackbar(message, {
          variant: 'success',
        });
        setActions({});
        setCurrentlyDeletedInputs([]);
        refetch();
        refetchForm();
      })
      .catch((error) => {
        enqueueSnackbar(error?.response?.data?.error?.message || error?.message, {
          variant: 'error',
        });
      });

    setLoading(false);
  };

  const handleDelete = (headingId, inputId) => {
    const headingInputs = [...userForm?.[headingId]?.stepInputs];

    const changedInputs = [];

    if (!headingInputs) return;

    if (mustExistInputs.includes(headingInputs[inputId].name)) {
      enqueueSnackbar('Dato polje ne možete obrisati!', {
        variant: 'error',
      });
      return;
    }

    if (headingInputs[inputId].name === 'city' || headingInputs[inputId].name === 'municipality') {
      const localCommunity =
        userForm[0].stepInputs.find(({ name }) => name === 'localCommunity') ||
        userForm[1].stepInputs.find(({ name }) => name === 'localCommunity') ||
        userForm[2].stepInputs.find(({ name }) => name === 'localCommunity');

      if (localCommunity) {
        enqueueSnackbar(
          `Ne možete obrisati polje ${headingInputs[inputId].label} prije nego što obrišite polje ${localCommunity.label}`,
          { variant: 'error' }
        );
        return;
      }
    }

    headingInputs[inputId].userPrivilege = null;
    headingInputs[inputId].stepOrderWeb = 999;

    changedInputs.push(headingInputs[inputId]);

    const [removed] = headingInputs.splice(inputId, 1);

    for (let i = inputId; i < headingInputs.length; i++) {
      headingInputs[i].stepOrderWeb = i + 1;
      changedInputs.push(headingInputs[i]);
    }

    updateAction(changedInputs);

    setUserForm((oldRows) => {
      const rows = [...(oldRows || [])];

      rows.splice(headingId, 1, { ...userForm[headingId], stepInputs: headingInputs });

      return rows;
    });

    const inputExistInCurrentForm = data.some(
      ({ formId, userPrivilege }) => formId === removed.formId && userPrivilege
    );

    setDeletedInputs((oldRows) => [...oldRows, removed]);
    if (inputExistInCurrentForm) setCurrentlyDeletedInputs((oldRows) => [...oldRows, removed]);
  };

  const updateAction = (formInputs) => {
    setActions((oldActions) => {
      const actions = { ...oldActions };

      formInputs.forEach((formInput) => {
        actions[formInput.formId] = {
          label: formInput.label,
          stepWeb: formInput?.stepWeb,
          stepOrderWeb: formInput?.stepOrderWeb,
          userPrivilege: formInput?.userPrivilege,
          componentType: formInput.componentType,
        };
      });

      return actions;
    });
  };

  const moveDeleted = (sourceIndex, destinationHeadingIndex, destinationIndex) => {
    const nonActiveInputs = [...deletedInputs];
    const destinationHeading = { ...userForm[destinationHeadingIndex] };

    const destinationHeadingInputs = [...destinationHeading.stepInputs];

    const [removed] = nonActiveInputs.splice(sourceIndex, 1);
    removed.userPrivilege = 1;
    destinationHeadingInputs.splice(destinationIndex, 0, removed);

    const changedInputs = [];

    const destinationInputLength = destinationHeadingInputs.length;

    for (let i = destinationIndex; i < destinationInputLength - 1; i++) {
      destinationHeadingInputs[i].stepOrderWeb = destinationHeadingInputs[i + 1].stepOrderWeb;
      changedInputs.push(destinationHeadingInputs[i]);
    }

    destinationHeadingInputs[destinationInputLength - 1].stepOrderWeb =
      destinationHeadingInputs[destinationInputLength - 2]?.stepOrderWeb + 1 || destinationInputLength + 1;
    destinationHeadingInputs[destinationIndex].stepWeb = destinationHeading.stepWeb;

    changedInputs.push(destinationHeadingInputs[destinationInputLength - 1]);
    changedInputs.push(destinationHeadingInputs[destinationIndex]);

    updateAction(changedInputs);

    setUserForm((oldRows) => {
      const rows = [...(oldRows || [])];

      rows.splice(destinationHeadingIndex, 1, { ...destinationHeading, stepInputs: destinationHeadingInputs });

      return rows;
    });

    setDeletedInputs(nonActiveInputs);
  };

  const move = (sourceIndex, sourceHeadingIndex, destinationHeadingIndex, destinationIndex) => {
    const sourceHeading = { ...userForm[sourceHeadingIndex] };
    const destinationHeading = { ...userForm[destinationHeadingIndex] };

    const sourceHeadingInputs = [...sourceHeading.stepInputs];
    const destinationHeadingInputs = [...destinationHeading.stepInputs];

    const [removed] = sourceHeadingInputs.splice(sourceIndex, 1);
    destinationHeadingInputs.splice(destinationIndex, 0, removed);

    const destinationInputLength = destinationHeadingInputs.length;
    const sourceInputLength = sourceHeadingInputs.length;

    const changedInputs = [];

    for (let i = sourceIndex; i < sourceInputLength; i++) {
      sourceHeadingInputs[i].stepOrderWeb = i + 1;
      changedInputs.push(sourceHeadingInputs[i]);
    }

    for (let i = destinationIndex; i < destinationInputLength - 1; i++) {
      destinationHeadingInputs[i].stepOrderWeb = destinationHeadingInputs[i + 1].stepOrderWeb;
      changedInputs.push(destinationHeadingInputs[i]);
    }

    destinationHeadingInputs[destinationInputLength - 1].stepOrderWeb =
      destinationHeadingInputs[destinationInputLength - 2]?.stepOrderWeb + 1 || destinationInputLength + 1;
    destinationHeadingInputs[destinationIndex].stepWeb = destinationHeading.stepWeb;

    changedInputs.push(destinationHeadingInputs[destinationInputLength - 1]);
    changedInputs.push(destinationHeadingInputs[destinationIndex]);

    updateAction(changedInputs);

    setUserForm((oldRows) => {
      const rows = [...(oldRows || [])];

      rows.splice(sourceHeadingIndex, 1, { ...sourceHeading, stepInputs: sourceHeadingInputs });

      rows.splice(destinationHeadingIndex, 1, { ...destinationHeading, stepInputs: destinationHeadingInputs });

      return rows;
    });
  };

  const reorder = (startIndex, endIndex, headingIndex) => {
    if (startIndex === (null || undefined) || endIndex === (null || undefined)) return;

    const formHeading = { ...userForm[headingIndex] };
    const formHeadingInputs = [...formHeading.stepInputs];

    const startIndexStepOrder = formHeadingInputs[startIndex].stepOrderWeb;

    const [removed] = formHeadingInputs.splice(startIndex, 1);
    formHeadingInputs.splice(endIndex, 0, removed);

    const changedInputs = [];

    if (startIndex < endIndex) {
      for (let i = endIndex; i > startIndex; i--) {
        formHeadingInputs[i].stepOrderWeb = formHeadingInputs[i - 1].stepOrderWeb;
        changedInputs.push(formHeadingInputs[i]);
      }
      formHeadingInputs[startIndex].stepOrderWeb = startIndexStepOrder;
      changedInputs.push(formHeadingInputs[startIndex]);
    } else if (startIndex > endIndex) {
      for (let i = endIndex; i < startIndex; i++) {
        formHeadingInputs[i].stepOrderWeb = formHeadingInputs[i + 1].stepOrderWeb;
        changedInputs.push(formHeadingInputs[i]);
      }
      formHeadingInputs[startIndex].stepOrderWeb = startIndexStepOrder;
      changedInputs.push(formHeadingInputs[startIndex]);
    }
    updateAction(changedInputs);

    setUserForm((oldRows) => {
      const rows = [...(oldRows || [])];

      rows.splice(headingIndex, 1, { ...formHeading, stepInputs: formHeadingInputs });

      return rows;
    });
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (
      (source.droppableId === 'deleted' && destination.droppableId === 'deleted') ||
      destination.droppableId === 'deleted'
    )
      return;

    const sourceInput =
      source.droppableId !== 'deleted'
        ? userForm[Number(source.droppableId)].stepInputs[source.index]
        : deletedInputs[source.index];

    if (
      (sourceInput.stepWeb !== 3 && destination.droppableId === '2' && sourceInput.stepMoveable === 'N') ||
      (sourceInput.stepWeb === 3 && destination.droppableId !== '2' && sourceInput.stepMoveable === 'N')
    ) {
      enqueueSnackbar(
        `Ne možete prebaciti polje namjenjeno za  ${sourceInput.stepWeb}. korak u ${
          Number(destination.droppableId) + 1
        }. korak!`,
        {
          variant: 'error',
        }
      );
      return;
    }

    if (sourceInput.name === 'city' || sourceInput.name === 'municipality') {
      const localCommunityHeading =
        userForm[0].stepInputs.findIndex(({ name }) => name === 'localCommunity') === -1
          ? userForm[1].stepInputs.findIndex(({ name }) => name === 'localCommunity') === -1
            ? { heading: 2, index: userForm[2].stepInputs.findIndex(({ name }) => name === 'localCommunity') }
            : { heading: 1, index: userForm[1].stepInputs.findIndex(({ name }) => name === 'localCommunity') }
          : { heading: 0, index: userForm[0].stepInputs.findIndex(({ name }) => name === 'localCommunity') };

      if (localCommunityHeading.index !== -1) {
        if (
          Number(destination.droppableId) > localCommunityHeading.heading ||
          (source.droppableId === destination.droppableId && destination.index >= localCommunityHeading.index) ||
          (Number(destination.droppableId) === localCommunityHeading.heading &&
            destination.index > localCommunityHeading.index)
        ) {
          enqueueSnackbar(
            `Polje ${sourceInput.label} ne može ići poslije polja ${
              userForm[localCommunityHeading.heading].stepInputs[localCommunityHeading.index].label
            }!`,
            {
              variant: 'error',
            }
          );
          return;
        }
      }
    }

    if (sourceInput.name === 'localCommunity') {
      const cityInput =
        userForm[0].stepInputs.find(({ name }) => name === 'city' || name === 'municipality') ||
        userForm[1].stepInputs.find(({ name }) => name === 'city' || name === 'municipality') ||
        userForm[2].stepInputs.find(({ name }) => name === 'city' || name === 'municipality');

      if (!cityInput) {
        enqueueSnackbar(
          `Ne možete dodati polje ${sourceInput.label} dok se ne doda polje ${
            data.find(({ name }) => name === 'city') ? 'Grad' : 'Opština'
          }!`,
          {
            variant: 'error',
          }
        );
        return;
      }

      if (
        Number(destination.droppableId) + 1 < cityInput.stepWeb ||
        (Number(destination.droppableId) + 1 === cityInput.stepWeb && destination.index < cityInput.stepOrderWeb)
      ) {
        enqueueSnackbar(`Polje ${sourceInput.label} ne može ići prije polja ${cityInput.label}!`, {
          variant: 'error',
        });
        return;
      }
    }

    if (source.droppableId === 'deleted') {
      moveDeleted(source.index, Number(destination.droppableId), destination.index);
      return;
    }

    if (source.droppableId === destination.droppableId) {
      reorder(source.index, destination.index, Number(source.droppableId));
      return;
    }

    move(source.index, Number(source.droppableId), Number(destination.droppableId), destination.index);

    return;
  };

  if (formError || formHistoryError) return <ServerError error={formError || formHistoryError} />;

  return (
    <Page title="Uređivanje forme">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        {isLoadingForm || isLoadingFormHistory ? (
          <LoadingScreen />
        ) : (
          <Box marginRight={collapse ? '18rem' : '0'} sx={{ transition: '.1s ease-in-out' }}>
            <FormActionModal
              open={actionModalOpen}
              setOpen={setActionModalOpen}
              data={registry}
              isLoading={isLoadingRegistry}
              refetchEmailActions={refetchEmailActions}
            />
            <FormHistoryModal
              open={open}
              setOpen={setOpen}
              setPage={setPage}
              page={page}
              returnHeaderForStep={returnHeaderForStep}
              forms={forms || []}
            />
            <FormEmailsActionModal
              data={emailActions || []}
              registry={registry}
              open={openEmailAction}
              setOpen={setOpenEmailAction}
              error={emailActionsError}
              refetch={refetchEmailActions}
              loading={isLoadingEmailActions || isRefetchingEmailActions}
            />
            <IconButton
              onClick={(_) => setCollapsed(!collapse)}
              sx={{
                position: 'fixed',
                top: '50%',
                right: 0,
                marginRight: collapse ? '17rem' : '-1rem',
                transition: '.1s ease-in-out',
              }}
            >
              {collapse ? (
                <IoIosArrowDropright size={40} color={theme.palette.primary.main} />
              ) : (
                <IoIosArrowDropleft size={40} color={theme.palette.primary.main} />
              )}
            </IconButton>
            <Box spacing={1} mb={3}>
              <Typography variant="h4" display="inline" sx={{ paddingRight: 1 }}>
                Aktuelna verzija #{forms[0].version}
              </Typography>
              <Typography variant="body1" color="textSecondary" display="inline">
                {moment(forms[0].createdAt).format('DD-MM-YYYY')}
              </Typography>
            </Box>
            <Box
              mb={3}
              display="flex"
              justifyContent="space-between"
              flexDirection={{ xs: 'column', md: 'row' }}
              gap={2}
            >
              <SplitButton
                options={[
                  {
                    name: 'Historija forme',
                    component: (
                      <Typography variant="h4" fontSize="1.1rem!important">
                        Historija forme
                      </Typography>
                    ),
                    action: () => {
                      setOpen(true);
                      setCollapsed(false);
                    },
                    disabled: isRefetching || isRefetchingForm,
                    loading: isRefetching || isRefetchingForm,
                    color: 'primary',
                    variant: 'outlined',
                    variantDropDown: 'outlined',
                    included: true,
                  },
                  {
                    name: 'Pregled akcija',
                    component: (
                      <Typography variant="h4" fontSize="1.1rem!important">
                        Pregled akcija
                      </Typography>
                    ),
                    action: () => {
                      setOpenEmailAction(true);
                      setCollapsed(false);
                    },
                    disabled: isLoadingEmailActions || isLoadingRegistry,
                    loading: isLoadingEmailActions || isLoadingRegistry,
                    color: 'primary',
                    variant: 'outlined',
                    variantDropDown: 'outlined',
                    included: layoutConfig.zahtjevi.prikazFormaAktivnosti === 'enabled',
                  },
                ]}
                canEdit
                padding={0}
                height={40.39}
              />
              <Box display="flex" justifyContent="space-between" gap={2}>
                {layoutConfig.zahtjevi.prikazFormaAktivnosti === 'enabled' && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setActionModalOpen(true);
                      setCollapsed(false);
                    }}
                  >
                    <Typography variant="h4" fontSize="1.1rem!important">
                      Dodavanje akcije
                    </Typography>
                  </Button>
                )}
                <Button
                  variant="contained"
                  disabled={!Object.keys(actions)?.length || loading || isRefetchingForm}
                  onClick={handleSubmit}
                  color="primary"
                  sx={{
                    border: '1px solid rgba(145, 158, 171, 0.8)!important',
                  }}
                >
                  {loading && <CircularProgress size={30} />}
                  {!loading && (
                    <Typography variant="h4" fontSize="1.1rem!important">
                      Spasi promjene
                    </Typography>
                  )}
                </Button>
              </Box>
            </Box>
            <Box
              display="flex"
              gap={3}
              flexWrap="wrap"
              sx={{
                '& > *': {
                  flex: {
                    xl: '1 20%',
                    xs: '1 100%',
                  },
                },
              }}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                {userForm.map((value, headerIndex) => (
                  <Droppable key={value.formId} droppableId={headerIndex.toString()}>
                    {(provided) => (
                      <Paper
                        elevation={1}
                        sx={{ '&': { marginBottom: '30px' } }}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <Box p={2}>{returnHeaderForStep(value.stepWeb)}</Box>
                        <Box display="flex" flexDirection="column">
                          {value.stepInputs.map((input, inputIndex) => (
                            <Draggable
                              key={input.formId}
                              draggableId={input.formId.toString()}
                              index={inputIndex}
                              isDragDisabled={
                                collapse ||
                                inputLabelChanging.stepIndex !== null ||
                                inputLabelChanging.stepInputIndex !== null
                              }
                            >
                              {(provided, snapshot) => (
                                <Row
                                  ref={provided.innerRef}
                                  snapshot={snapshot}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Box
                                    display="flex"
                                    gap={1}
                                    alignItems="center"
                                    sx={
                                      headerIndex === inputLabelChanging.stepIndex &&
                                      inputIndex === inputLabelChanging.stepInputIndex
                                        ? { width: '100%' }
                                        : {}
                                    }
                                  >
                                    <BigIcon icon="carbon:draggable" inline={true} color="rgba(0, 0, 0, 0.3)" />
                                    {headerIndex === inputLabelChanging.stepIndex &&
                                    inputIndex === inputLabelChanging.stepInputIndex ? (
                                      <form
                                        style={{
                                          width: '85%',
                                        }}
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                          const { label } = e.target.elements;
                                          const headerStep = { ...userForm[headerIndex] };
                                          const headingInputs = [...headerStep.stepInputs];
                                          headingInputs[inputIndex].label = label.value;

                                          updateAction([headingInputs[inputIndex]]);

                                          setInputLabelChanging({ stepIndex: null, stepInputIndex: null });

                                          setUserForm((oldRows) => {
                                            const rows = [...(oldRows || [])];

                                            rows.splice(headerIndex, 1, {
                                              ...headerStep,
                                              stepInputs: headingInputs,
                                            });

                                            return rows;
                                          });
                                        }}
                                        autoComplete="off"
                                      >
                                        <Box display="flex" gap={1} alignItems="center">
                                          <TextField
                                            hiddenLabel
                                            name="label"
                                            id="standard-basic"
                                            defaultValue={input.label || ''}
                                            size="small"
                                            variant="standard"
                                            fullWidth
                                            inputProps={{ maxLength: 300 }}
                                          />

                                          <IconButton
                                            onClick={() =>
                                              setInputLabelChanging({
                                                stepIndex: null,
                                                stepInputIndex: null,
                                              })
                                            }
                                          >
                                            <FcCancel size={20} inline="true" />
                                          </IconButton>
                                          <IconButton type="submit">
                                            <FcCheckmark size={22} inline="true" />
                                          </IconButton>
                                        </Box>
                                      </form>
                                    ) : (
                                      <Typography variant="inherit" fontWeight={600} align="center">
                                        {input.label}
                                      </Typography>
                                    )}
                                    {!(
                                      headerIndex === inputLabelChanging.stepIndex &&
                                      inputIndex === inputLabelChanging.stepInputIndex
                                    ) && returnIconForInputType(input.type, theme)}
                                  </Box>
                                  <Box>
                                    {inputLabelChanging.stepIndex === null &&
                                      inputLabelChanging.stepInputIndex === null &&
                                      !(input.label || '').includes('Broj rata') && (
                                        <IconButton
                                          onClick={() =>
                                            setInputLabelChanging({
                                              stepIndex: headerIndex,
                                              stepInputIndex: inputIndex,
                                            })
                                          }
                                        >
                                          <MdModeEdit style={{ cursor: 'pointer' }} inline="true" />
                                        </IconButton>
                                      )}
                                    <IconButton onClick={(_) => handleDelete(headerIndex, inputIndex)}>
                                      <Icon icon="mdi:delete" />
                                    </IconButton>
                                  </Box>
                                </Row>
                              )}
                            </Draggable>
                          ))}
                        </Box>
                        {provided.placeholder}
                      </Paper>
                    )}
                  </Droppable>
                ))}
                <Droppable droppableId="deleted">
                  {(provided) => (
                    <SideBar
                      width={collapse ? '18rem!important' : '0'}
                      display={collapse ? 'block!important' : 'none!important'}
                      p={collapse ? 2 : 0}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      position={collapse ? 'fixed' : 'static'}
                    >
                      <Typography sx={{ mb: '20px' }} variant="h4" fontSize="1.25rem!important">
                        Nekorištena polja: {deletedInputs.length}
                      </Typography>
                      {deletedInputs.map((deletedInput, deletedInputIndex) => (
                        <Draggable
                          key={deletedInput.formId}
                          draggableId={deletedInput.formId.toString()}
                          index={deletedInputIndex}
                        >
                          {(provided, snapshot) => (
                            <Paper
                              sx={{
                                border: '0.5px solid rgba(0, 0, 0, 0.2)',
                                borderRadius: '5px',
                                '&': { marginBottom: '20px' },
                              }}
                              ref={provided.innerRef}
                              snapshot={snapshot}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Box display="flex" gap={1} alignItems="center" p={1}>
                                <BigIcon icon="carbon:draggable" inline={true} color="rgba(0, 0, 0, 0.3)" />
                                <Typography
                                  variant="inherit"
                                  fontWeight={600}
                                  align="center"
                                  fontSize="0.85rem!important"
                                >
                                  {deletedInput.label}
                                </Typography>
                              </Box>
                            </Paper>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </SideBar>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
          </Box>
        )}
      </Container>
    </Page>
  );
}

const FormHeader = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;
  position: relative;
`;

const Row = styled(Paper)`
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
`;

const BigIcon = styled(Icon)`
  font-size: 23px;
`;

const SideBar = styled(Box)`
  height: 100%;
  width: 0;
  display: none;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  border-left: 1px solid rgba(145, 158, 171, 0.24);
  border-left-style: dashed;
`;
