import React from 'react';
import { useQuery } from 'react-query';
import { Tooltip, useTheme } from '@mui/material';
import { FaFacebook, FaGlobe, FaViber, FaHandshake, FaInstagram } from 'react-icons/fa';
import { fetchActivationTypes } from '../api';

const ActivationTypeIcon = ({ code, config }) => {
  const { palette } = useTheme();
  const { data, isLoading, error } = useQuery(['activationTypes'], fetchActivationTypes, {
    enabled: config.zahtjevi.manualnoKreiranjeZahtjeva === 'enabled',
  });

  if (isLoading || error) return null;

  let icon = null;
  if (code === '8') icon = <FaFacebook color={palette.info.main} />;
  else if (code === '11') icon = <FaViber color={palette.primary.main} />;
  else if (code === '14') icon = <FaHandshake size={18} color="#6400a399" />;
  else if (code === '15') icon = <FaInstagram size={18} style={{marginBottom:-3,borderRadius: 7,color:'#fff',background:' #d6249f',background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)'}} />
  else icon = <FaGlobe color={palette.secondary.main} style={{ marginRight: 4 }} />;

  const title = data?.find(({ value }) => value === code)?.text || 'Web zahtjev';

  return (
    <Tooltip title={`Izvor: ${title}`}>
      <span>{icon}</span>
    </Tooltip>
  );
};

export default ActivationTypeIcon;
