import {
  clientColumns as partnerClientColumns,
  applicationColumns as partnerApplicationColumns,
} from './partnerLoanApplication';
import {
  clientColumns as mikraClientColumns,
  applicationColumns as mikraApplicationColumns,
} from './mikraLoanApplication';

export const clientColumns = process.env.REACT_APP_CLIENT === 'partner' || process.env.REACT_APP_CLIENT === 'partnermkd' ? partnerClientColumns : mikraClientColumns;

export const applicationColumns =
  process.env.REACT_APP_CLIENT === 'partner' || process.env.REACT_APP_CLIENT === 'partnermkd' ? partnerApplicationColumns : mikraApplicationColumns;
