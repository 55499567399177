import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { IoMdClose } from 'react-icons/io';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  Table,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  tableCellClasses,
  IconButton,
} from '@mui/material';
import styled from '@emotion/styled';
import ServerError from './ServerError';
import { deleteEmailAction } from '../api';

const FormEmailsActionModal = ({ data, registry, open, setOpen, error, refetch, loading }) => {
  const [selectedAction, setSelectedAction] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const { mutate, isLoading } = useMutation(deleteEmailAction, {
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      let errorMessage = error.message;
      if (error?.response?.data?.error) {
        const { message, sentry } = error.response.data.error;
        errorMessage = `${message} - ${sentry}`;
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    },
  });

  if (error)
    return (
      <Dialog open={open} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Box>
            E-mail akcije
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 17,
              }}
            >
              <IoMdClose />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ServerError error={error} />
        </DialogContent>
      </Dialog>
    );

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Box>
          E-mail akcije
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 17,
            }}
          >
            <IoMdClose />
          </IconButton>
        </Box>
      </DialogTitle>
      {selectedAction ? (
        <DialogContent>
          <br />
          <DialogContentText id="alert-dialog-description">
            Da li ste sigurni da želite izbrisati akciju "{selectedAction?.opisVrsteSlanja}"?
          </DialogContentText>
          <br />
          <Box display="flex" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                mutate(selectedAction.vrstaSlanja);
                setSelectedAction(null);
              }}
            >
              Potvrdi
            </Button>
            <Button variant="outlined" color="error" onClick={() => setSelectedAction(null)}>
              Odustani
            </Button>
          </Box>
        </DialogContent>
      ) : (
        <DialogContent>
          <br />
          {loading || isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="500px">
              <CircularProgress size="4rem" />
            </Box>
          ) : (
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center" sx={{ backgroundColor: 'black' }}>
                      Korak
                    </StyledTableCell>
                    <StyledTableCell align="center">Kada</StyledTableCell>
                    <StyledTableCell align="center">Vrsta slanja</StyledTableCell>
                    <StyledTableCell align="center">E-mailovi</StyledTableCell>
                    <StyledTableCell align="center">Opštine</StyledTableCell>
                    <StyledTableCell align="center">Akcija</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map(({ korak, kada, emailovi, opstine, opisVrsteSlanja, vrstaSlanja }, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">{korak}</StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={kada.charAt(0).toUpperCase() + kada.slice(1)}
                          color={kada === 'prije' ? 'primary' : 'info'}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">{opisVrsteSlanja}</StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <Box display="flex" flexDirection="column" gap={2}>
                          {emailovi
                            .split(';')
                            .map(
                              (email) =>
                                email !== '-' && (
                                  <Chip key={email} label={email} size="small" color="primary" variant="outlined" />
                                )
                            )}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box display="flex" flexDirection="column" gap={1}>
                          {opstine &&
                            opstine
                              .split(',')
                              .map((opstina) => (
                                <Chip
                                  label={
                                    (registry?.municipalities || []).find(
                                      ({ value: municipality }) => municipality === opstina
                                    )?.text
                                  }
                                  key={opstina}
                                />
                              ))}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          onClick={(_) =>
                            setSelectedAction({ korak, kada, emailovi, opstine, opisVrsteSlanja, vrstaSlanja })
                          }
                        >
                          <Icon icon="mdi:delete" />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
      )}
    </Dialog>
  );
};

const StyledTableCell = styled(TableCell)`
  ${({ theme }) => `
    margin: 0!important;
    &.${tableCellClasses.head} {
      background-color: ${theme.palette.common.black};
      color: ${theme.palette.common.white};
    }

    &.${tableCellClasses.body} {
      font-size: 14;
    }

  `}
`;

const StyledTableRow = styled(TableRow)`
  ${({ theme }) => `
    &:nth-of-type(odd) {
      background-color: ${theme.palette.action.hover};
    }

    &:last-child td, &:last-child th {
      border: 0!important;
    }
    `}
`;

export default FormEmailsActionModal;
