import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, Box, IconButton } from '@mui/material';
import { FiPlus, FiX } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { step1Schema, step2Schema } from '@mikra/validation';
import { Form, Input, Select, PhoneInput } from '@components/rhf-mui5';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import LocalCommunitySelect from '../components/LocalCommunitySelect';
import { createLoanApplication } from '../api';
import LocalCommunitySelectByCity from './LocalCommunitySelectByCity';

const CreateApplicationDialog = ({ registry }) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const schema = step1Schema.concat(step2Schema);
  const methods = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { mutate } = useMutation(createLoanApplication, {
    onSuccess: () => {
      enqueueSnackbar(`Uspješno kreiran novi zahtjev`, {
        variant: 'success',
      });
      queryClient.invalidateQueries('loanApplications');
      methods.reset();
      handleClose();
    },
    onError: (error) => {
      let errorMessage = error.message;
      if (error?.response?.data?.error) {
        const { message, sentry } = error.response.data.error;
        errorMessage = `${message} - ${sentry}`;
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    },
  });

  const handleSubmit = (values) => {
    mutate(values);
  };

  return (
    <>
      <Button variant="text" color="primary" size="small" onClick={handleClickOpen} endIcon={<FiPlus />}>
        Novi
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Novi zahtjev{' '}
          <Box position="absolute" right={2} top={2}>
            <IconButton aria-label="close" onClick={handleClose}>
              <FiX />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText gutterBottom>
            Nakon kreiranja, ostatak zahtjeva kompletirajte na glavnoj formi za zahtjev.
          </DialogContentText>
          <Box my={3}>
            <Form methods={methods} onSubmit={handleSubmit} spacing={3}>
              <Select label="Način aktiviranja" name="activationType" options={registry.activationTypes} />
              <Input label="Iznos" name="amount" type="number" md={6} />
              <Input label="Broj rata" name="repaymentPeriod" type="number" md={6} />
              <Input label="Namjena" name="loanPurpose" />
              <Input label="Ime" name="firstName" md={6} />
              <Input label="Prezime" name="lastName" md={6} />
              <Input label="Adresa stanovanja" name="address" md={6} />
              <Select label="Grad" name="city" options={registry.cities} md={6} />
              <LocalCommunitySelectByCity />
              <PhoneInput label="Mobilni telefon" name="mobilePhone" type="tel" />
              <Button
                type="submit"
                fullWidth
                disableElevation
                variant="contained"
                color="primary"
                size="large"
                disabled={methods.isSubmitting}
              >
                Kreiraj zahtjev
              </Button>
            </Form>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateApplicationDialog;
