import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import styled from 'styled-components';
import useResponsive from '../hooks/useResponsive';

const StyledTableRow = styled(TableRow)`
  &&.MuiTableCell-root {
    font-weight: 400 !important;
    background: blue;
    color: red;
  }
`;

const StyledTableCell = styled(TableCell)`
  font-size: 0.75rem;
  font-weight: 600;

  &&.MuiTableCell-head {
    font-weight: 400;
    font-size: 0.75rem;
  }
`;

const ApplicationStepsOverview = ({ data, numberOfLoanApplications }) => {
  const isDesktop = useResponsive('up', 'lg');
  return (
    <TableContainer component={Box} minHeight={150} mt={3}>
      <Table aria-label="Popunjenost koraka" size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Korak</StyledTableCell>
            <StyledTableCell align="center">Ukupno</StyledTableCell>
            <StyledTableCell align="center">Procentualno</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow key={1}>
            <StyledTableCell component="th" scope="row">
              <Box color="#004F82 !important">
                <Typography variant="caption">
                  <b>Info.</b>
                </Typography>
              </Box>
            </StyledTableCell>
            <StyledTableCell align="center">{data?.step2 || 0}</StyledTableCell>
            <StyledTableCell align="center">
              {numberOfLoanApplications ? (((data?.step2 || 0) / numberOfLoanApplications) * 100).toFixed(2) : 0}%
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key={2}>
            <StyledTableCell component="th" scope="row">
              <Box color="#08660D !important">
                <Typography variant="caption">
                  <b>Analiza</b>
                </Typography>
              </Box>
            </StyledTableCell>
            <StyledTableCell align="center">{data?.step3 || 0}</StyledTableCell>
            <StyledTableCell align="center">
              {numberOfLoanApplications ? (((data?.step3Count || 0) / numberOfLoanApplications) * 100).toFixed(2) : 0}%
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key={3}>
            <StyledTableCell component="th" scope="row">
              <Box color="#B78103 !important">
                <Typography variant="caption">
                  <b>Završio</b>
                </Typography>
              </Box>
            </StyledTableCell>
            <StyledTableCell align="center">{data?.step4 || 0}</StyledTableCell>
            <StyledTableCell align="center">
              {numberOfLoanApplications ? (((data?.step4 || 0) / numberOfLoanApplications) * 100).toFixed(2) : 0}%
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ApplicationStepsOverview;
