import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

// @mui
import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';

// components
import TableContainer from '@mui/material/TableContainer';
import Page from '../components/Page';

import AnalyticsStatsCard from '../components/AnalyticsStatsCard';
import AnalyticsTable from '../components/AnalyticsTable';

// assets
import { fetchActivityStatistics } from '../api';

export default function Analytics() {
  const theme = useTheme();

  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();

  const [firstChartData, setFirstChartData] = useState();
  const [secondChartData, setSecondChartData] = useState();
  const [thirdChartData, setThirdChartData] = useState();

  const [minutesGeneral, setMinutesGeneral] = useState();
  const [secondsGeneral, setSecondsGeneral] = useState();

  const { data, error, isLoading } = useQuery('fetchStatistics', fetchActivityStatistics);

  useEffect(() => {
    setMinutes(Math.floor(data?.averageApplicationTime?.razlika / 60) || 0);
    setSeconds(
      data?.averageApplicationTime?.razlika - Math.floor(data?.averageApplicationTime?.razlika / 60) * 60 || 0
    );

    setMinutesGeneral(Math.floor(data?.averageApplicationTimeGeneral?.razlika / 60) || 0);
    setSecondsGeneral(
      data?.averageApplicationTimeGeneral?.razlika -
        Math.floor(data?.averageApplicationTimeGeneral?.razlika / 60) * 60 || 0
    );

    let sum1 = 0,
      sum2 = 0;

    for (let i = 0; i < data?.stepsData?.length; i++) {
      sum1 += data?.stepsData[i]?.stepPercentage;
    }
    setFirstChartData(sum1);

    for (let i = 0; i < data?.timeData?.length; i++) {
      sum2 += data?.timeData[i]?.stepPercentage;
    }
    setSecondChartData(sum2);

    setThirdChartData(data?.processingData?.length);
  }, [data]);

  return (
    <Page title="Analitike">
      <Box display="flex" flexDirection="column" px={5} py={3}>
        <Typography variant="h3">
          <b>Analitike</b>
        </Typography>

        <Box mt={5}>
          <Grid container spacing={8}>
            <Grid item xl={4} lg={6} xs={12}>
              <Paper>
                {error && <></>}
                {!error && (
                  <Box display="flex" flexDirection="row" p={1} sx={{ justifyContent: 'space-between', height: '125px' }}>
                    {!isLoading && 
                      <AnalyticsStatsCard
                      data={data?.stepsData}
                      title={'Procenat kompletiranih zahtjeva'}
                      displayData={(data?.completeApplicationsPercentage?.procenat).toFixed(0) + ' %'}
                      changeRate={
                        data?.completeApplicationsPercentageChange == 'Nema podataka'
                          ? 'Nema podataka'
                          : (data?.completeApplicationsPercentageChange).toFixed(0)
                      }
                      color={[theme.palette.primary.main]}
                      colorDark={[theme.palette.primary.dark]}
                      type={'1'}
                      hasChartData={firstChartData}
                    />}
                  </Box>
                )}
              </Paper>
            </Grid>
            <Grid item xl={4} lg={6} xs={12}>
              <Paper>
              {error && <></>}
                {!error && (
                  <Box display="flex" flexDirection="row" p={1} sx={{ justifyContent: 'space-between', height: '125px' }}>
                    {!isLoading && 
                      <AnalyticsStatsCard
                      data={data?.timeData}
                      title={'Prosječno vrijeme kompletiranih zahtjeva'}
                      displayData={`${minutes?.toFixed(0)}m ${seconds?.toFixed(0)}s`}
                      changeRate={`${minutesGeneral?.toFixed(0)}m ${secondsGeneral?.toFixed(0)}s`}
                      color={[theme.palette.primary.dark]}
                      type={'2'}
                      hasChartData={secondChartData}
                    />}
                  </Box>
                )}
              </Paper>
            </Grid>
            <Grid item xl={4} lg={12} xs={12}>
              <Paper>
              {error && <></>}
                {!error && (
                  <Box display="flex" flexDirection="row" p={1} sx={{ justifyContent: 'space-between', height: '125px' }}>
                    {!isLoading && 
                      <AnalyticsStatsCard
                      data={data?.processingData}
                      title={'Procenat generisanih ugovora'}
                      displayData={(data?.processedApplicationsPercentage?.procenat).toFixed(0) + ' %'}
                      changeRate={
                        data?.processedApplicationsPercentageChange == 'Nema podataka'
                          ? 'Nema podataka'
                          : (data?.processedApplicationsPercentageChange).toFixed(0)
                      }
                      type={'3'}
                      hasChartData={thirdChartData}
                    />}
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <TableContainer component={Paper} sx={{ marginTop: 5, padding: 1 }}>
          <AnalyticsTable />
        </TableContainer>
      </Box>
    </Page>
  );
}
