import {
  Badge,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox as MuiCheckbox,
  Tooltip,
  Typography,
  CircularProgress,
  Chip,
  useTheme,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { ToggleButton } from '@mui/material';
import ApplicationListFilter from './ApplicationListFilter';
import Empty from './Empty';
import { GrDocumentDownload, GrSearch, GrCalendar } from 'react-icons/gr';
import { FixedSizeList } from 'react-window';
import ServerError from './ServerError';
import { fetchLoanApplications, createLoanApplicationExcelFile } from '../api';
import moment from 'moment';
import { statusColor } from '../utils/colors';
import { transparentize } from 'polished';
import { useQuery } from 'react-query';
import { SearchBar } from '@components/rhf-mui5';
import ActivationTypeIcon from './ActivationTypeIcon';
import { FiLoader } from 'react-icons/fi';
import { Grow, ClickAwayListener, IconButton } from '@mui/material';
import CreateApplicationDialog from './CreateApplicationDialog';

import { useRecoilState } from 'recoil';
import { userState } from '../state/useUserContext';

import layoutConfig from '../layouts/layout-config.js';

const WindowedRow = React.memo(({ index, style, data }) => {
  const { loanApplications, selected, handleSelect, config } = data;
  const item = loanApplications?.[index];
  const isSelected = item.id === selected;
  const reactionRequired = item.status === 'completed';

  return (
    <Box {...style}>
      <Link
        key={item.id}
        to={`/applications/${item.id}`}
        highlight={reactionRequired ? 1 : 0}
        style={{ textDecoration: 'none' }}
      >
        {index !== 0 && <Divider />}
        <StyledListItem selected={isSelected} onClick={() => handleSelect(item.id)}>
          <ListItemIcon>
            <MuiCheckbox checked={isSelected} inputProps={{ 'aria-labelledby': item.id }} />
          </ListItemIcon>
          <ListItemText
            id={item.id}
            primary={
              <Box display="flex" alignItems="center" minHeight={20} gap={item.activationType ? 0.7 : 0}>
                <Box mt={0.4}>
                  <ActivationTypeIcon code={item.activationType} config={config} />{' '}
                </Box>

                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle2" style={{ color: 'black' }} fontWeight={400}>
                    {item.firstName} {item.fathersName && '(' + item?.fathersName + ')'} {item.lastName}
                  </Typography>
                  {item?.activationType == 14 && <CustomChip label={item?.createdBy} />}
                  {item?.promoCode && <CustomChip label={'AKCIJSKI KREDIT'} style={{ marginBottom: 2 }} />}
                </Box>
              </Box>
            }
            primaryTypographyProps={{ variant: 'body2' }}
            secondary={
              <Box
                display="flex"
                fontWeight={500}
                mt={0.1}
                flexWrap={{ lg: 'wrap', xl: 'nowrap' }}
                flexDirection={{ lg: 'column', xl: 'row' }}
              >
                <Box pr="6px" mr="6px" borderRight="1px solid #e6e6e6">
                  <Typography variant="subtitle2" fontWeight={400} style={{ color: '#004F82    ' }}>
                    WZ-{item.id}
                    {item.erpKey ? `#${item.erpKey}` : ''}
                  </Typography>
                </Box>
                {moment(item.updatedAt || item.createdAt).calendar()}
              </Box>
            }
            secondaryTypographyProps={{ variant: 'subtitle2' }}
          />
          <ListItemSecondaryAction>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="subtitle2" color="textPrimary" fontWeight={400}>
                {item.amount} BAM
              </Typography>
              <Typography
                variant="overline"
                fontWeight={500}
                fontSize={13}
                style={{ color: statusColor(item.statusCode) }}
              >
                {item.statusName}
              </Typography>
            </Box>
          </ListItemSecondaryAction>
        </StyledListItem>
      </Link>
    </Box>
  );
});

const editRoles = ['DLS_ADMIN', 'DLS_WEBZAHTJEV'];

const ApplicationList = ({ selected, handleSelect, registry, labeledStatuses = [] }) => {
  const [config, setConfig] = useState(layoutConfig);

  const filteredStatus = labeledStatuses?.filter((status) => status?.defaultFilter).map((status) => status?.code) || [];

  const [status, setStatus] = useState(filteredStatus);
  const [erpKey, setErpKey] = useState('all');
  const [repaymentLoan, setRepaymentLoan] = useState(null);
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);
  const [office, setOffice] = useState('');
  const [loanProduct, setLoanProduct] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [creatingExcelFile, setCreatingExcelFile] = useState(false);
  const { data, error, isLoading } = useQuery(
    ['loanApplications', status, searchValue, startingDate, endingDate, office, loanProduct, erpKey, repaymentLoan],
    () =>
      fetchLoanApplications({
        status,
        searchValue,
        startingDate,
        endingDate,
        office,
        loanProduct,
        erpKey,
        repaymentLoan,
      }),
    {
      refetchInterval: 75000,
    }
  );

  let { pathname: url } = useLocation();

  const [toggle, setToggle] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showOther, setShowOther] = useState(true);

  const [authState] = useRecoilState(userState);

  const canEdit = editRoles.includes(authState?.user?.role || 'DLS_ADMIN');

  useEffect(
    () =>
      toggle
        ? setStatus(['kontk'])
        : setStatus(filteredStatus.filter((value) => !['contractGenerated', 'granted'].includes(value))),
    [toggle]
  );

  return (
    <List
      dense
      subheader={
        <ClickAwayListener onClickAway={() => setShowSearch(false)}>
          <ApplicationFilterWrapper
            display="flex"
            px={3}
            py={4}
            borderBottom="1px solid #e6e6e6"
            minHeight="32px"
            gap={0.2}
            justifyContent="space-between"
            alignItems="center"
            color="text.secondary"
            flexWrap={{ lg: 'wrap', xl: 'noWrap' }}
          >
            {showOther && (
              <>
                <Box display="flex" gap={2}>
                  <Typography noWrap={true} variant="h6" fontWeight={500} color="black">
                    Broj zahtjeva ({data?.loanApplications.length})
                  </Typography>
                </Box>
                <Box>
                  {config.zahtjevi.manualnoKreiranjeZahtjeva === 'enabled' && canEdit && (
                    <CreateApplicationDialog registry={registry} />
                  )}
                </Box>
              </>
            )}
            <Grow
              timeout={350}
              in={showSearch}
              appear={false}
              onEnter={() => setShowOther(false)}
              onExited={() => setShowOther(true)}
            >
              <Box sx={{ width: '100%' }}>
                <SearchBar hideSearchBar={() => setShowSearch(false)} setSearchValue={setSearchValue}></SearchBar>
              </Box>
            </Grow>
            {!showSearch && showOther && (
              <Box>
                <IconButton onClick={() => setShowSearch(!showSearch)} size="small" sx={{ border: 0, height: '33px' }}>
                  <GrSearch size={21} color="black" style={{ marginBottom: 1 }} />
                </IconButton>
              </Box>
            )}
            <IconButtonWithSpinner
              onClick={() =>
                createLoanApplicationExcelFile(
                  {
                    searchValue,
                    status,
                    startingDate,
                    endingDate,
                    office,
                    loanProduct,
                    erpKey,
                  },
                  setCreatingExcelFile
                )
              }
              variant="contained"
              disabled={creatingExcelFile}
              sx={{ border: 0, height: '33px' }}
            >
              {creatingExcelFile ? (
                <FiLoader className="spinner" />
              ) : (
                <GrDocumentDownload size={21} style={{ marginBottom: 1 }} color="black" />
              )}
            </IconButtonWithSpinner>

            <Tooltip title="Neostvaren kontakt" placement="top">
              <ToggleButton
                onClick={() => setToggle((prev) => !prev)}
                selected={toggle}
                value={toggle}
                size="small"
                sx={{ border: 0, height: '33px', marginRight: 1 }}
              >
                <Badge color="secondary">
                  <GrCalendar size={24} style={{ marginBottom: 1.5 }} color="black" />
                </Badge>
              </ToggleButton>
            </Tooltip>
            <ApplicationListFilter
              status={status}
              setStatus={setStatus}
              setStartingDate={setStartingDate}
              setEndingDate={setEndingDate}
              registry={registry}
              labeledStatuses={labeledStatuses}
              setLoanProductInput={setLoanProduct}
              setOfficeInput={setOffice}
              startingDate={startingDate}
              endingDate={endingDate}
              officeInput={office}
              loanProductInput={loanProduct}
              erpKeyRadio={erpKey}
              setErpKeyRadio={setErpKey}
              repaymentLoanRadio={repaymentLoan}
              setRepaymentLoanRadio={setRepaymentLoan}
            />
          </ApplicationFilterWrapper>
        </ClickAwayListener>
      }
    >
      {error && <ServerError error={error} />}
      {isLoading && (
        <Box display="flex" justifyContent="center" minHeight={900} alignItems="center">
          <CircularProgress />
        </Box>
      )}
      {!error && !isLoading && !data?.loanApplications?.length && (
        <Empty
          title="Nema rezultata"
          subtitle="Nema podnesenih zahtjeva, ili nisu pronađeni zahtjevi koji odgovaraju kriterijima pretrage."
          noLottie
          leftAligned
        />
      )}
      <FixedSizeList
        height={3400}
        itemSize={76}
        itemCount={data?.loanApplications?.length || 0}
        itemData={{ loanApplications: data?.loanApplications || [], handleSelect, url, selected, registry, config }}
        className="no-scrollbars"
      >
        {WindowedRow}
      </FixedSizeList>
    </List>
  );
};

const StyledListItem = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  height: 76px;
  background-color: ${(props) => transparentize(0.94, props.bg ? props.bg : 'white')};
`;

const IconButtonWithSpinner = styled(IconButton)`
  .spinner {
    animation: spin infinite 2s linear;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `;

const ApplicationFilterWrapper = styled(Box)`
  flex-wrap: nowrap;

  @media only screen and (max-width: 1650px) {
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 1199px) {
    flex-wrap: nowrap;
  }
`;

const CustomChip = styled(Chip)`
  max-height: 21px;
  margin-left: 6px;
  font-size: 10px;
`;

export default ApplicationList;
