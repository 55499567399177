import { Alert, Box, Grid, Typography, useTheme } from '@mui/material';

import ClientProfilePopover from './ClientProfilePopover';
import { FaUserTie } from 'react-icons/fa';
import Logo from './Logo';
import React from 'react';
import moment from 'moment';
import percentCompleted from '../utils/percentCompleted';

const ApplicationHeader = ({ data, id }) => {
  const { palette } = useTheme();
  const { employee, reactedAt, createdAt, updatedAt } = data || {};
  const percent = percentCompleted(data);

  return (
    <Grid container>
      <Grid item container xs={12} spacing={2}>
        <Box display="flex" gap={{ xl: 5, lg: 3 }} mt={5} mb={2} minWidth="100%" flexWrap={{ lg: 'wrap' }}>
          <Box alignSelf="center">
            <ClientProfilePopover client={data} percent={percent} />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle2" gutterBottom>
              Radnik
            </Typography>
            {employee ? (
              <Typography variant="body2">
                <FaUserTie color={palette.primary.main} style={{ marginRight: 2, marginBottom: -1 }} /> {employee} (
                {moment(reactedAt).calendar()})
              </Typography>
            ) : (
              <Typography variant="body2">Zahtjev nije preuzet</Typography>
            )}
            <Typography variant="subtitle2" gutterBottom sx={{ mt: 3 }}>
              Podnesen
            </Typography>
            <Typography variant="body2">{moment(createdAt).calendar()}</Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            {' '}
            <Typography variant="subtitle2" gutterBottom>
              Zadnji put ažuriran
            </Typography>
            <Typography variant="body2">
              Prije{' '}
              {moment()
                .from(moment(updatedAt || createdAt))
                .substring(3)}
            </Typography>
            <Typography variant="subtitle2" gutterBottom sx={{ mt: 3 }}>
              Verzija forme
            </Typography>
            <Typography variant="body2">#{data.version}</Typography>
          </Box>
          {data?.activationType == 14 && (
            <Box display="flex" flexDirection="column">
              <Typography variant="subtitle2" gutterBottom sx={{ mt: 3 }}>
                Kooperant
              </Typography>
              <Typography variant="body2">{data?.createdBy}</Typography>
            </Box>
          )}
        </Box>
      </Grid>

      {data?.promoCode && (
        <Grid xs={12} item>
          <Alert variant="outlined" severity="info" sx={{ mt: 3 }}>
            <Typography color="info" variant="subtitle2">
              Klijent aplicirao za akcijski kredit!
            </Typography>
          </Alert>
        </Grid>
      )}
      {data?.statusCode === 'odb' ? (
        <Grid xs={12} item>
          <Alert variant="outlined" severity="warning" icon={false} sx={{ mt: 3 }}>
            <Typography color="text.primary" variant="subtitle2" gutterBottom>
              Razlog odbijanja: {data?.abandonmentReason ? data?.abandonmentReason : 'Nepoznat'}
            </Typography>
            <Typography color="text.primary" variant="subtitle2">
              Komentar: {data?.abandonmentDescription ? data?.abandonmentDescription : 'Nije unesen komentar'}
            </Typography>
          </Alert>
        </Grid>
      ) : (
        <></>
      )}
      {data?.statusCode === 'kontk' ? (
        <Grid xs={12} item>
          <Alert variant="outlined" severity="info" sx={{ mt: 3 }}>
            <Typography color="info" variant="subtitle2">
              Zahtjev odgođen za:{' '}
              {data?.postponedUntil ? new Date(data?.postponedUntil).toLocaleDateString('en-GB') : ' Nepoznat datum'}
            </Typography>
          </Alert>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default ApplicationHeader;
