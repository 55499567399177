import React from 'react';

import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { Form, Select, Input } from '@components/rhf-mui5';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { FiLoader, FiX } from 'react-icons/fi';
import { useMutation, useQueryClient } from 'react-query';
import { forwardLoanApplication } from '../api';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const ForwardModal = ({ open, setOpen, offices, applicationId }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm({
    defaultValues: {},
    mode: 'onBlur',
    resolver: yupResolver(yupSchema),
  });
  const { reset } = methods;

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const commonMutationProps = (message) => ({
    onSuccess: () => {
      reset();
      handleClose();
      enqueueSnackbar(message, {
        variant: 'success',
      });
      queryClient.invalidateQueries('loanApplications');
      queryClient.invalidateQueries('loanApplication');
    },
    onError: (error) => {
      console.log(error.message, error.response);
      let errorMessage = error.message;
      if (error?.response?.data?.error) {
        const { message, sentry } = error.response.data.error;
        errorMessage = `${message} - ${sentry}`;
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    },
  });

  const { mutate: forward, isLoading: isForwarding } = useMutation(
    forwardLoanApplication,
    commonMutationProps('Zahtjev uspješno proslijeđen')
  );

  const handleSubmit = (values) => {
    forward({ id: applicationId, ...values });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">
          Proslijedi zahtjev
          <Box position="absolute" right={2} top={2}>
            <IconButton aria-label="close" onClick={handleClose}>
              <FiX />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            Odabrani promoter će dobiti obavijest putem e-maila da obradu zahtjeva treba nastaviti kroz iMikro.
          </DialogContentText>
          <Form methods={methods} onSubmit={handleSubmit} spacing={2}>
            <Select label="Kancelarija" name="office" options={offices} />
            <Input label="Napomena" name="remark" multiline rows={2} />
            <Box textAlign="right">
              <Button
                startIcon={isForwarding && <FiLoader className="spinner" />}
                disabled={isForwarding}
                type="submit"
                color="primary"
              >
                Proslijedi
              </Button>
            </Box>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const requiredString = (name) =>
  yup
    .string()
    .required(`Polje ne smije biti prazno`)
    .typeError(`${name || 'Polje'} ne smije biti prazno`);

const yupSchema = yup.object({
  office: requiredString(),
});

export default ForwardModal;
