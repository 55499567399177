import { useState } from 'react';
// @mui
import { Box, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { Route, Routes } from 'react-router';
import { fetchRegistry, getFormInformation, getStatuses } from '../api';
import LoadingScreen from '../components/LoadingScreen';
// components
import Page from '../components/Page';
// assets
import Application from '../components/Application';
import ApplicationList from '../components/ApplicationList';
import Empty from '../components/Empty';
import ServerError from '../components/ServerError';

export default function Applications() {
  const [selectedId, setSelectedId] = useState();
  const { data, error, isLoading } = useQuery('registry', fetchRegistry);
  const {
    data: formData,
    error: formError,
    isLoading: isLoadingForm,
  } = useQuery('form', () => getFormInformation({ active: 'D' }));
  const {
    data: labeledStatuses,
    isLoading: isLoadingLabels,
    error: labelError,
  } = useQuery('labeledStatuses', getStatuses);

  return (
    <Page title="Zahtjevi">
      {(isLoading || isLoadingForm || isLoadingLabels) && <LoadingScreen />}
      {error && <ServerError error={error} />}
      {formError && <ServerError error={formError} />}
      {labelError && <ServerError error={labelError} />}
      {!isLoading && !error && !isLoadingForm && !formError && !isLoadingLabels && !labelError && (
        <Box display="flex" flexDirection="column" px={5} py={3}>
          <Typography variant="h3">
            <b>Lista zahtjeva</b>
          </Typography>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mt={5}
            gap={3}
            flexWrap={{
              lg: 'nowrap',
              xs: 'wrap',
            }}
          >
            <Box
              bgcolor="white"
              borderRadius={1}
              boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
              flexBasis={{ xs: '100%' }}
              maxWidth={{ lg: '33%' }}
            >
              <ApplicationList
                selected={selectedId}
                handleSelect={(id) => setSelectedId(id)}
                registry={data}
                labeledStatuses={labeledStatuses}
              />
            </Box>
            <Box
              flexBasis={{ lg: '64%', xs: '100%' }}
              borderRadius={1}
              bgcolor="white"
              pt={2}
              pr={2}
              boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
              display="flex"
              flexDirection="column"
            >
              <Routes>
                <Route
                  path=""
                  exact
                  element={
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                      <Empty
                        title="Odaberite zahtjev"
                        subtitle="Trenutno nije odabran zahtjev, ili nema aktuelnih zahtjeva"
                      />
                    </Box>
                  }
                />
                <Route path="/:id" element={<Application registry={data} formData={formData} />} />
              </Routes>
            </Box>
          </Box>
        </Box>
      )}
    </Page>
  );
}
