export const clientColumns = [
  { header: 'Ime', key: 'firstName' },
  { header: 'Ime oca', key: 'fathersName' },
  { header: 'Prezime', key: 'lastName' },
  { header: 'JMBG', key: 'jmbg' },
  { header: 'Broj lične karte', key: 'lk' },
  { header: 'Adresa stanovanja', key: 'address' },
  { header: 'Opština', key: 'municipality', registryKey: 'municipalities' },
  { header: 'Mjesna zajednica', key: 'localCommunity', registryKey: 'localCommunities' },
  { header: 'Mobilni telefon', key: 'mobilePhone' },
  { header: 'Fiksni telefon', key: 'landlinePhone' },
  { header: 'Email', key: 'email' },
  { header: 'Spol', key: 'gender', registryKey: 'genders' },
  { header: 'Bračni status', key: 'martialStatus', registryKey: 'martialStatus' },
  { header: 'Vrsta naselja', key: 'urbanOrRural', registryKey: 'urbanOrRural' },
  { header: 'Broj članova u porodici', key: 'householdSize' },
  { header: 'Radni status', key: 'employmentStatus', registryKey: 'employmentStatus' },
  { header: 'Naziv firme poslodavca', key: 'employer' },
];

export const applicationColumns = [
  { header: 'Broj zahtjeva', key: 'erpKey' },
  { header: 'Zahtjev broj', key: 'erpKeyAlt' },
  { header: 'Status', key: 'status' },
  { header: 'Radnik', key: 'employee' },

  ...clientColumns,
  { header: 'Iznos', key: 'amount' },
  { header: 'Predloženi iznos', key: 'recommendedAmount' },
  { header: 'Broj rata', key: 'repaymentPeriod' },
  { header: 'Predloženi broj rata', key: 'recommendedRepaymentPeriod' },
  { header: 'Kreditni proizvod', key: 'loanProduct', registryKey: 'loanProducts' },
  { header: 'Grace period', key: 'gracePeriod' },
  { header: 'Predloženi grace period ', key: 'recommendedGracePeriod' },
  { header: 'Kancelarija', key: 'office', registryKey: 'offices' },
  { header: 'Namjena kredita', key: 'loanPurpose', registryKey: 'loanPurposes' },
  { header: 'Tip kredita', key: 'loanType', registryKey: 'loanType' },
  { header: 'Vrsta isplate', key: 'payoutType', registryKey: 'payoutType' },
  { header: 'Ovim kreditom želim da', key: 'repaymentLoan' },

  { header: 'Napomena', key: 'remark' },
  { header: 'Mjesečni prihodi', key: 'monthlyIncome' },
  { header: 'Mjesečna primanja domaćinstva', key: 'monthlyHouseholdIncome' },
  { header: 'Mjesečni troškovi domaćinstva', key: 'monthlyHouseholdExpenses' },
  { header: 'Postojeća kreditna zaduženja', key: 'householdExistingLoanAmount' },
  { header: 'Klijent ima postojeće kredite?', key: 'clientHasExistingLoan', type: 'boolean' },
  { header: 'Domaćinstvo ima postojeće kredite?', key: 'householdHasExistingLoan', type: 'boolean' },

  { header: 'Prenošen', key: 'transmittedAt' },
  { header: 'Kreiran', key: 'createdAt' },
  { header: 'Zadnji put ažuriran', key: 'updatedAt' },
  { header: 'Rekreiran', key: 'reactedAt' },
  { header: 'Razlog odbijanja', key: 'abandonmentReason' },
  { header: 'Opis odbijanja', key: 'abandonmentDescription' },
  { header: 'Razlog proslijeđivanja', key: 'forwardRemark' },
];
