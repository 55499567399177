import { yupResolver } from '@hookform/resolvers/yup';
import styled from '@emotion/styled';
import { Form, Select, FormConnect } from '@components/rhf-mui5';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Box,
  IconButton,
  Button,
  Collapse,
  CircularProgress,
  Select as MultiSelect,
  Chip,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Autocomplete,
  FormHelperText,
} from '@mui/material';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { patchFormActions } from '../api';

export const emptyStringToNull = (value, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
};

const checkIsEmailActionNumberValidation = {
  is: (action) => action === '1',
  then: yup.number().typeError('Polje ne smije biti prazno'),
};

const scehma = yup.object({
  action: yup.string().required('Polje ne smije biti prazno'),
  step: yup
    .number()
    .transform((value) => (isNaN(value) ? null : value))
    .nullable()
    .when('action', checkIsEmailActionNumberValidation),
  stepAction: yup
    .string()
    .transform(emptyStringToNull)
    .nullable()
    .when('action', {
      is: (action) => action === '1',
      then: yup.string().required().typeError('Polje ne smije biti prazno'),
    }),
  emailType: yup
    .string()
    .transform(emptyStringToNull)
    .nullable()
    .when('action', {
      is: (action) => action === '1',
      then: yup.string().required().typeError('Polje ne smije biti prazno'),
    }),
  customEmails: yup.array().when('emailType', {
    is: (emailType) => emailType === 'rucno-uneseni',
    then: yup
      .array()
      .of(yup.string().trim().email('Unesite ispravan email'))
      .min(1, 'Potrebno je barem jedan email unijeti'),
  }),
  municipalities: yup.array().when('emailType', {
    is: (emailType) => emailType === 'admin-opstine',
    then: yup.array().min(1, 'Potrebno je barem jednu opštinu izabrati'),
  }),
});

const emailType = [
  { text: 'E-mailovi određeni odabirom opštine komitenta', value: 'komitent-opstina' },
  { text: 'E-mailovi ručno uneseni', value: 'rucno-uneseni' },
  { text: 'E-mailovi odabrani opštinama korisnika koji kreira akciju', value: 'admin-opstine' },
];

const FormActionModal = ({ open, setOpen, data, isLoading, refetchEmailActions }) => {
  const [confirming, setConfirming] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const method = useForm({
    mode: 'onBlur',
    resolver: yupResolver(scehma),
  });

  const { mutate, isLoading: isInserting } = useMutation(patchFormActions, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno je dodata akcija', {
        variant: 'success',
      });
      method.reset({
        action: '',
        step: '',
        stepAction: '',
        emailType: '',
        customEmails: [],
        municipalities: [],
      });
      setOpen(false);
      refetchEmailActions();
    },
    onError: (error) => {
      let errorMessage = error.message;
      if (error?.response?.data?.error) {
        const { message, sentry } = error.response.data.error;
        errorMessage = `${message} - ${sentry}`;
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    },
  });

  const actionValue = useWatch({
    control: method.control,
    name: 'action',
  });

  if (isLoading || isInserting)
    return (
      <Dialog open={open} maxWidth="lg" fullWidth>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center" height="500px">
            <CircularProgress size="4rem" />
          </Box>
        </DialogContent>
      </Dialog>
    );

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Box>
          Dodavanje akcije
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 17,
            }}
          >
            <IoMdClose />
          </IconButton>
        </Box>
      </DialogTitle>
      {confirming ? (
        <DialogContent>
          <br />
          <DialogContentText id="alert-dialog-description">
            Da li ste sigurni da želite dodati novu akciju? Dodavanjem nove akcije uklanja se stara akcija "
            {emailType.find(({ value }) => value === method.getValues('emailType'))?.text}".
          </DialogContentText>
          <br />
          <Box display="flex" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                const values = { ...method.getValues() };
                setConfirming(false);
                mutate(values);
              }}
            >
              Potvrdi
            </Button>
            <Button variant="outlined" color="error" onClick={() => setConfirming(false)}>
              Odustani
            </Button>
          </Box>
        </DialogContent>
      ) : (
        <DialogContent>
          <br />
          <Form methods={method}>
            <Select name="action" options={[{ text: 'Slanje e-maila', value: '1' }]} label="Akcija" fullWidth />
            <EmailForm
              visible={actionValue === '1'}
              control={method.control}
              municipalities={data?.municipalities || []}
            />
            <Button variant="outlined" onClick={() => setConfirming(true)}>
              Spasi akciju
            </Button>
          </Form>
        </DialogContent>
      )}
    </Dialog>
  );
};

const EmailForm = ({ visible, control, municipalities }) => {
  const emailTypeValue = useWatch({
    control,
    name: 'emailType',
  });

  return (
    <Collapse in={visible} timeout="auto" unmountOnExit>
      <FormConnect>
        <StyledSelect
          name="step"
          options={[
            { text: '3', value: 3 },
            { text: '4', value: 4 },
          ]}
          label="Korak"
          fullWidth
        />
      </FormConnect>
      <FormConnect>
        <StyledSelect
          name="stepAction"
          options={[
            { text: 'Prije koraka', value: 'prije' },
            { text: 'Poslije koraka', value: 'poslije' },
          ]}
          label="Kada"
          fullWidth
        />
      </FormConnect>
      <FormConnect>
        <StyledSelect name="emailType" options={emailType} label="Vrsta slanja" fullWidth />
      </FormConnect>
      <Collapse in={emailTypeValue === 'rucno-uneseni'} timeout="auto" unmountOnExit>
        <FormConnect>
          <Controller
            name="customEmails"
            defaultValue={[]}
            render={({ field: { onChange, value: emails }, fieldState }) => (
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={emails}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
                }
                onChange={(_, values) => {
                  onChange([...values]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="E-mailovi"
                    error={!!(fieldState.error?.message || Array.isArray(fieldState.error))}
                    helperText={
                      fieldState.error?.message ||
                      (Array.isArray(fieldState.error) &&
                        `"${fieldState.error.reduce(
                          (string, _, index) =>
                            (string += (emails[index] || '') + (index === fieldState.error.length - 1 ? '' : ', ')),
                          ''
                        )}"  nisu ispravni e-mailovi.`)
                    }
                  />
                )}
              />
            )}
          />
        </FormConnect>
      </Collapse>
      <Collapse in={emailTypeValue === 'admin-opstine'} timeout="auto" unmountOnExit>
        <FormConnect>
          <Controller
            name="municipalities"
            defaultValue={[]}
            render={({ field: { onChange, value }, fieldState }) => (
              <FormControl sx={{ width: '100%' }} error={!!fieldState.error?.message}>
                <InputLabel id="demo-multiple-name-label">Opštine</InputLabel>
                <MultiSelect
                  multiple
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={municipalities?.find(({ value: municipality }) => municipality === value).text}
                        />
                      ))}
                    </Box>
                  )}
                  input={<OutlinedInput label="Opštine" />}
                  MenuProps={MenuProps}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  fullWidth
                >
                  {municipalities.map(({ text, value }) => (
                    <MenuItem key={value} value={value}>
                      {text}
                    </MenuItem>
                  ))}
                </MultiSelect>
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </FormConnect>
      </Collapse>
    </Collapse>
  );
};

const StyledSelect = styled(Select)`
  margin-bottom: 24px;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default FormActionModal;
