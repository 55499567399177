import React, { useState } from 'react';
import styled from 'styled-components';
import ReactImageGallery from 'react-image-gallery';
import { ellipsis } from 'polished';
import { Box, Typography, Link, Chip, Fab } from '@mui/material';
import { FiFileText } from 'react-icons/fi';
import { FaPhotoVideo, FaTimes } from 'react-icons/fa';
import Thumbnail from './Thumbnail';

const FileItem = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 24px;
  min-height: 32px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;

  p {
    font-size: 0.875rem;
    ${ellipsis('400px')};
    ${({ theme }) => theme?.breakpoints?.down('sm')} {
      ${ellipsis('300px')};
    }
    ${({ theme }) => theme?.breakpoints?.down('xs')} {
      ${ellipsis('200px')};
    }
  }
`;

const GalleryWrapper = styled(Box)`
  .image-gallery {
    width: 32.5vw;
    height: auto;
    background: #f9fafb;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  }

  .image-gallery-slide {
    min-height: 65vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-gallery-slide img {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
  }
`;

const categoryMappingPartner = {
  101: 'Lična Karta',
  103: 'Potvrda o boravku',
  105: 'Dokaz o primanjima',
};

const categoryMappingMikra = {
  687: 'Lična Karta',
  692: 'Potvrda o boravku',
  695: 'Dokaz o primanjima',
}

const Documentation = ({ documents }) => {
  const [showGallery, setShowGallery] = useState(false);
  const toggleGallery = () => setShowGallery((s) => !s);

  const staticUrl = `${process.env.REACT_APP_API_ENDPOINT}static`;

  const images = documents
    .filter(({ mimeType }) => mimeType?.includes('image'))
    .map(({ fileName }) => ({
      original: `${staticUrl}/${fileName}`,
      thumbnail: `${staticUrl}/${fileName}`,
    }));

  return (
    <>
      {showGallery && (
        <GalleryWrapper position="fixed" left={130} bottom={16}>
          <ReactImageGallery items={images} showPlayButton={false} />
        </GalleryWrapper>
      )}
      <Box position="fixed" bottom={88} right={32} color="red">
        <Fab color="secondary" disabled={!images.length} onClick={toggleGallery}>
          {showGallery ? <FaTimes size={24} /> : <FaPhotoVideo size={24} color="#fff" />}
        </Fab>
      </Box>

      {documents?.length === 0 && <Typography variant="body1">Trenutno nema priloženih dokumenata.</Typography>}
      {documents?.map(({ id, fileName, fileSize, documentType, mimeType }) => (
        <FileItem key={id}>
          <Box display="flex" alignItems="center">
            {mimeType?.includes('image') ? (
              <Thumbnail imageSrc={`${staticUrl}/${fileName}`} filename={fileName} />
            ) : (
              <Link href={`${staticUrl}/${fileName}`} target="_blank" rel="noopener noreferrer" download>
                <FiFileText size={40} />
              </Link>
            )}
            <Box ml={1} display="flex" flexDirection="column">
              <Typography variant="body1">{fileName}</Typography>
              <Typography variant="caption">{`${((fileSize || 0 * 1024) / (1024 * 1024)).toFixed(2)}MB`}</Typography>
            </Box>
          </Box>
          <Chip label={process.env.REACT_APP_CLIENT === 'mikra' ? categoryMappingMikra[documentType] : categoryMappingPartner[documentType]} style={{ backgroundColor: '#e6e6e6', marginLeft: 'auto' }} />
        </FileItem>
      ))}
    </>
  );
};

export default Documentation;
