import { Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components

import LoadingScreen from '../components/LoadingScreen';
import PrivateRoute from '../components/PrivateRoute';
import Applications from '../pages/Applications';
import Analytics from '../pages/Analytics';
import DraggableForm from '../pages/DraggableForm';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Page404 from '../pages/Page404';
import UsersManagement from '../pages/UsersManagement';

//Lazy loading on routes
// const Home = loadable(() => import('../pages/Home'));
// const Login = loadable(() => import('../pages/Login'));
// const Applications = loadable(() => import('../pages/Applications'));
// const DraggableForm = loadable(() => import('../pages/DraggableForm'));
// const UsersManagement = loadable(() => import('../pages/UsersManagement'));
// const LoadingScreen = loadable(() => import('../components/LoadingScreen'));
// const PrivateRoute = loadable(() => import('../components/PrivateRoute'));
// const Page404 = loadable(() => import('../pages/Page404'));

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/home')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/home" replace />, index: true },
        { path: '/', element: <Navigate to="/home" replace />, index: true },
        {
          path: '/home',
          element: (
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          ),
        },
        {
          path: '/applications/*',
          element: (
            <PrivateRoute>
              <Applications />
            </PrivateRoute>
          ),
        },
        {
          path: '/analytics',
          element: (
            <PrivateRoute>
              <Analytics />
            </PrivateRoute>
          ),
        },
        {
          path: '/form',
          element: (
            <PrivateRoute>
              <DraggableForm />
            </PrivateRoute>
          ),
        },
        {
          path: '/users',
          element: (
            <PrivateRoute>
              <UsersManagement />
            </PrivateRoute>
          ),
        },
      ],
    },

    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
        { path: 'login', element: <Login /> },
      ],
    },
  ]);
}
