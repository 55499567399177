import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  CircularProgress,
  useTheme,
} from '@mui/material';
import { FaCheckDouble, FaExclamationTriangle, FaHandPointer } from 'react-icons/fa';
import { FiMail, FiSend, FiXOctagon } from 'react-icons/fi';
import React, { useRef, useState } from 'react';
import {
  fetchAbandonmentReasons,
  fetchLoanApplication,
  integrateLoanApplication,
  patchLoanApplicationReaction,
  saveLoanApplication,
  rejectLoanApplication,
  postponeLoanApplication,
  getDisableActionstatuses,
} from '../api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';

import ForwardModal from './ForwardModal';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ApplicationForm from './ApplicationForm';
import ApplicationHeader from './ApplicationHeader';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ServerError from './ServerError';
import SplitButton from './SplitButton';
import LoadingScreen from './LoadingScreen';
import palette from '../theme/palette';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { userState } from '../state/useUserContext';
import useResponsive from '../hooks/useResponsive';

import layoutConfig from '../layouts/layout-config.js';

const Header = styled.header`
  ${({ theme }) => `
    padding: ${theme.spacing(2)}px ${theme.spacing(3)}px;
  
    button {
      margin-right: 8px;
    }
    ${theme.breakpoints.down('lg')} {
      border-top: 1px solid #e6e6e6;
    }
  
    .spinner {
      animation: spin infinite 2s linear;
    }
  
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    `}
`;

const editRoles = ['DLS_ADMIN', 'DLS_WEBZAHTJEV'];

const Application = ({ registry, formData }) => {
  const [config, setConfig] = useState(layoutConfig);
  const isDesktop = useResponsive('up', 'xl');

  const queryClient = useQueryClient();
  const theme = useTheme();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [fModalOpen, setFModalOpen] = useState(false);
  const [authState, setAuthState] = useRecoilState(userState);

  const actionRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const openReasons = Boolean(anchorEl);

  const [anchorDate, setAnchorDate] = useState(null);
  const [date, setDate] = useState(new Date());
  const [download, setDownload] = useState(true);
  const [reason, setReason] = useState(null);
  const [description, setDescription] = useState(null);
  const openDate = Boolean(anchorDate);

  const canEdit = editRoles.includes(authState?.user?.role || 'DLS_ADMIN');

  const {
    data: loanData,
    error: loanError,
    isLoading: isLoadingLoan,
    refetch: refetchLoanData,
  } = useQuery(['loanApplication', id], () => fetchLoanApplication(id));

  const {
    data: reasonsData,
    error: reasonsError,
    isLoading: isLoadingReasons,
  } = useQuery(['abandonmentReasons'], () => fetchAbandonmentReasons());

  const { data: disableActionData } = useQuery(['disableActionsStatus'], () => getDisableActionstatuses());

  const commonMutationProps = (message) => ({
    onSuccess: () => {
      enqueueSnackbar(message, {
        variant: 'success',
      });
      queryClient.invalidateQueries('loanApplications');
      queryClient.invalidateQueries('loanApplication');
      queryClient.invalidateQueries('disableActionsStatus');
    },
    onError: (error) => {
      let errorMessage = error.message;
      if (error?.response?.data?.error) {
        const { message, sentry } = error.response.data.error;
        errorMessage = `${message} - ${sentry}`;
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    },
  });

  const { mutate: save, isLoading: isSaving } = useMutation(
    saveLoanApplication,
    commonMutationProps('Podaci sačuvani u međubazu')
  );

  const { mutate: reject, isLoading: isRejecting } = useMutation(
    rejectLoanApplication,
    commonMutationProps('Aplikacija odbijena')
  );

  const { mutate: postpone, isLoading: isPostponing } = useMutation(
    postponeLoanApplication,
    commonMutationProps('Aplikacija odgođena')
  );

  const { mutate: integrate, isLoading: isIntegrating } = useMutation(
    integrateLoanApplication,
    commonMutationProps('Zahtjev uspješno prepisan u core sistem!')
  );

  const { mutate: employeeReact, isLoading: isEmployeeReacting } = useMutation(
    patchLoanApplicationReaction,
    commonMutationProps('Radnik je preuzeo zahtjev!')
  );

  const handleSubmit = (values) => {
    if (!loanData.employee) handleReaction();
    save({
      loanApplicationId: id,
      clientId: loanData.clientId,
      creditAnalysisId: loanData.creditAnalysisId,
      ...values,
    });
  };

  const handleIntegrate = () => {
    integrate(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorDate(null);
  };

  const handleReject = () => {
    reject({
      id,
      patch: { reason: reason, description: description },
    });
    handleClose();
  };

  const handleOpenReasons = () => {
    setAnchorEl(actionRef.current);
  };

  const handleOpenDate = () => {
    setAnchorDate(actionRef.current);
  };

  const handlePostpone = () => {
    handleClose();
    postpone({
      id,
      patch: { postponed_until: date.toLocaleString('bs', { timeZone: 'Europe/Belgrade' }) },
      loanData,
      download,
    });
  };

  const handleReaction = () => {
    employeeReact({ id, patch: { employee: authState?.user?.username, email: authState?.user?.email } });
  };

  const handleFModal = () => {
    setFModalOpen((prev) => !prev);
  };

  const { statusId, statusCode, employee, erpKey, erpKeyAlt } = loanData || {};
  const disabledStatus = disableActionData?.map((status) => status.id).includes(Number(statusId));

  const disabledActions =
    isIntegrating || isRejecting || isPostponing || !id || disabledStatus || !employee || loanData?.erpKey;

  const SplitOptions = [
    {
      name: 'Prepiši zahtjev',
      action: handleIntegrate,
      loading: isIntegrating,
      disabled: disabledActions,
      icon: <FiSend />,
      color: theme?.palette?.primary?.main,
      included: config.zahtjevi.prepisivanjeZahtjeva === 'enabled',
    },
    {
      name: 'Odbij zahtjev',
      action: handleOpenReasons,
      loading: isRejecting,
      disabled: disabledActions,
      icon: <FiXOctagon />,
      color: theme?.palette?.error?.main,
      included: config.zahtjevi.odbijanjeZahtjeva === 'enabled',
    },
    {
      name: 'Proslijedi zahtjev',
      icon: <FiMail />,
      action: handleFModal,
      disabled: disabledActions,
      color: theme?.palette?.warning?.main,
      included: config.zahtjevi.proslijedjivanjeZahtjeva === 'enabled',
    },
    {
      name: 'Odgodi zahtjev',
      icon: <FiSend />,
      action: handleOpenDate,
      disabled: disabledActions,
      color: theme?.palette?.secondary?.main,
      included: config.zahtjevi.odgadjanjeZahtjeva === 'enabled',
    },
  ];

  return (
    <>
      {(isLoadingLoan || isLoadingReasons) && (
        <Box display="flex" justifyContent="center" minHeight={900} alignItems="center">
          <CircularProgress />
        </Box>
      )}
      {!isLoadingLoan && !isLoadingReasons && (
        <>
          <ForwardModal open={fModalOpen} setOpen={setFModalOpen} offices={registry.offices} applicationId={id} />
          <Header>
            <Box
              display="flex"
              justifyContent="space-between"
              px={5}
              mt={1}
              flexWrap={{ xl: 'nowrap!important', lg: 'wrap!important' }}
              flexDirection={{ lg: 'column-reverse', xl: 'row' }}
              gap={{ lg: 3 }}
            >
              <Typography variant="h4" gutterBottom>
                {loanData?.erpKey ? `Zahtjev WZ-${id}#${loanData?.erpKeyAlt}` : `Zahtjev WZ-${id}`}
              </Typography>
              <Box display="flex" justifyContent="space-between" minWidth={{ xl: 390 }}>
                <Button
                  color="secondary"
                  disableElevation
                  variant="contained"
                  sx={{ maxHeight: 35, p: 2 }}
                  startIcon={employee ? <FaCheckDouble /> : <FaHandPointer />}
                  disabled={!canEdit || isEmployeeReacting || !!employee || !!loanData?.erpKey}
                  onClick={handleReaction}
                >
                  {employee ? `Zahtjev preuzet` : 'Preuzmi zahtjev'}
                </Button>
                <ClickAwayListener onClickAway={handleClose}>
                  <Box ref={actionRef}>
                    <SplitButton options={SplitOptions} canEdit={canEdit} />
                    <Popper
                      open={openReasons}
                      anchorEl={anchorEl}
                      style={{
                        maxWidth: 700,
                        height: 300,
                        overflow: 'auto',
                        border: `1px solid lightgray`,
                        borderRadius: '5px',
                      }}
                    >
                      <Paper>
                        <Box component="form" onSubmit={handleReject} p={3}>
                          <Typography variant="body2" gutterBottom>
                            Razlog odbijanja:{' '}
                          </Typography>
                          <RadioGroup>
                            {reasonsData?.map((data, index) => (
                              <FormControlLabel
                                key={index}
                                value={data?.value}
                                control={<Radio size="small" required />}
                                onClick={() => setReason(data?.value)}
                                label={<Typography variant="body2">{data?.text}</Typography>}
                              />
                            ))}
                          </RadioGroup>
                          <Typography variant="body2" style={{ marginTop: '5px' }} gutterBottom>
                            Opis:
                          </Typography>
                          <TextField
                            variant="outlined"
                            onChange={(e) => setDescription(e.target.value)}
                            fullWidth
                            multiline
                            maxRows={3}
                            required
                          />
                          <Button
                            variant="outlined"
                            color="secondary"
                            type="submit"
                            style={{ marginTop: '15px' }}
                            fullWidth
                          >
                            Potvrdi
                          </Button>
                        </Box>
                      </Paper>
                    </Popper>
                    <Popper open={openDate} anchorEl={anchorDate}>
                      <Paper>
                        <Box p={2}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                              label="Odgodite za:"
                              value={date}
                              onChange={setDate}
                              inputFormat="dd/MM/yyyy hh:mm a"
                              mask="__/__/____ __:__ _M"
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        </Box>
                        <Box ml={2} mr={2} mt={-1} pb={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked
                                size="small"
                                checked={download}
                                onChange={() => setDownload((prev) => !prev)}
                              />
                            }
                            label={<Typography variant="body2">Dodaj podsjetnik</Typography>}
                          />
                          <Button color="secondary" variant="outlined" size="small" onClick={handlePostpone} fullWidth>
                            Potvrdi
                          </Button>
                        </Box>
                      </Paper>
                    </Popper>
                  </Box>
                </ClickAwayListener>
              </Box>
            </Box>
          </Header>
          {loanError && <ServerError error={loanError} />}
          {!isLoadingLoan && !isLoadingReasons && !loanError && !reasonsError && (
            <Box borderRadius={2} bgcolor="#fff" px={{ _: 0, md: 6 }} py={{ _: 2, md: 2 }}>
              {['k1', 'k2', 'k3', 'k4'].includes(statusCode) && (
                <Box textAlign="end">
                  <Typography variant="caption">
                    <FaExclamationTriangle /> Klijent još nije kompletirao zahtjev
                  </Typography>
                </Box>
              )}
              <ApplicationHeader data={loanData} id={id} />
              <ApplicationForm
                id={id}
                formData={formData}
                defaultValues={loanData || {}}
                loading={isSaving}
                handleSubmit={handleSubmit}
                registry={registry}
                disabledStatus={disabledStatus}
                refetchLoanData={() => refetchLoanData()}
                canEdit={canEdit}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Application;
