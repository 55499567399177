import React from 'react';
import { useWatch } from 'react-hook-form';
import { FormConnect, Select } from '@components/rhf-mui5';

const LocalCommunitySelect = ({ control, localCommunities, defaultValue, ...inputProps }) => {
  const selectedMunicipality = useWatch({
    control,
    name: 'municipality',
    defaultValue,
  });

  const options = localCommunities?.filter(({ municipality }) => municipality === selectedMunicipality);
  return (
    <FormConnect>
      <Select label="Mjesna zajednica" name="localCommunity" options={options || []} {...inputProps} />
    </FormConnect>
  );
};

export default LocalCommunitySelect;
