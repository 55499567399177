import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { useQuery } from 'react-query';

import { statusColor } from '../utils/colors';

// assets
import ServerError from '../components/ServerError';
import LoadingScreen from '../components/LoadingScreen';
import Empty from '../components/Empty';

// @mui
import { Box, Typography, IconButton, useTheme, Tooltip, Pagination, CircularProgress } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// components
import styled from 'styled-components';

import { AiOutlineHome } from 'react-icons/ai';
import { BsClockHistory, BsFillSquareFill } from 'react-icons/bs';

import { fetchActivityList } from '../api';

const RightBorderCell = styled(TableCell)`
  border-right: 0.5px solid rgba(0, 0, 0, 0.2);
`;

const BottomBorderRow = styled(TableRow)`
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
`;

function parseDate(str_date) {
  return new Date(Date.parse(str_date));
}

function getTimeDiff(timeline, actor) {
  let t1, t2;

  if (actor == 'klijent') {
    t1 = parseDate(timeline[0]?.CREATED_AT);

    for (let i = 0; i < timeline.length; i++) {
      if (parseInt(timeline[i]?.STATUS_TO) <= 5 || parseInt(timeline[i]?.STATUS_TO) == 8) {
        t2 = parseDate(timeline[i]?.CREATED_AT);
      }
    }
  } else if (actor == 'radnik') {
    let n = timeline.length;

    if (parseInt(timeline[n - 1]?.STATUS_TO) <= 5 || parseInt(timeline[n - 1]?.STATUS_TO) == 8) {
      return '0m 0s';
    } else {
      t2 = parseDate(timeline[n - 1]?.CREATED_AT);

      for (let i = 0; i < timeline.length; i++) {
        if (parseInt(timeline[i]?.STATUS_TO) > 5 && parseInt(timeline[i]?.STATUS_TO) != 8) {
          t1 = parseDate(timeline[i]?.CREATED_AT);
          break;
        }
      }
      if (!t1) t1 = 0;
    }
  }

  let timeDiff = (t2 - t1) / 1000;
  let m = Math.floor(timeDiff / 60) || 0;
  let s = Math.floor(timeDiff - Math.floor(timeDiff / 60) * 60) || 0;
  return m + 'm ' + s + 's';
}

function HomeIcon() {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <IconButton
        disabled
        style={{
          color: theme.palette.common.white,
          background: theme.palette.primary.main,
          borderRadius: '2px',
          width: '48px',
          height: '48px',
        }}
      >
        <AiOutlineHome />
      </IconButton>
    </Box>
  );
}

function StatusBox({ statusName, statusColor }) {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="row" flexWrap="nowrap" gap={2}>
      <Box borderRadius={50} height={16} width={16} bgcolor={statusColor} />
      <Typography noWrap={true} variant="body2" style={{ fontWeight: 'bold' }}>
        {statusName}
      </Typography>
    </Box>
  );
}

const headers = [
  { header: 'Zahtjev', width: 0.225, padding: 1.5 },
  { header: 'Status', width: 0.175, padding: 1.5 },
  { header: 'Tok obrade', width: 0.225, padding: 1.5 },
  { header: 'Klijent', width: 0.125, padding: 1.5 },
  { header: 'Radnik', width: 0.125, padding: 1.5 },
  { header: 'Kreiran', width: 0.125, padding: 1.5 },
];

export default function AnalyticsTable() {
  const [itemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [emptyArray, setEmptyArray] = useState(new Array(10).fill(0));

  const { data, error, refetch, isLoading, isRefetching } = useQuery(
    [page],
    () => fetchActivityList(page, itemsPerPage),
    {
      refetchOnWindowFocus: false,
    }
  );

  const items = data?.list || [];
  const itemCount = data?.numberOfLoanApplications?.count || 0;

  useEffect(() => {
    if (page === 1) {
      setTotalItems(itemCount);
      const intTotalPages = Math.floor(itemCount / itemsPerPage);

      if (itemCount <= 10) setTotalPages(1);
      else if (itemCount / 10 === intTotalPages) setTotalPages(intTotalPages);
      else setTotalPages(intTotalPages + 1);
    }
  }, [itemCount]);

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    setEmptyArray(new Array(10 - items?.length || 0).fill(0));
  }, [itemCount]);

  const theme = useTheme();

  const colorPhase1 = theme.palette.primary.main;
  const colorPhase2 = theme.palette.primary.dark;

  const rowHeight = '82px';

  return (
    <>
      {error && <ServerError />}
      {!error && (
        <>
          <Table sx={{ tbody: { height: `calc(${itemsPerPage} * ${rowHeight})` } }}>
            <TableHead>
              <TableRow>
                {headers.map(({ header, ...rest }) => (
                  <TableCell key={header} sx={{ ...rest }}>
                    <Typography variant="body1" color="inherit">
                      {header}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {!error && (isLoading || isRefetching) && (
              <TableBody>
                <TableRow sx={{ position: 'relative' }}>
                  <TableCell>
                    <Box position="absolute" left="50%" top="50%">
                      <CircularProgress size={64} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {!(error || isLoading || isRefetching) && (
              <TableBody>
                {items?.map((row) => (
                  <BottomBorderRow key={row?.id} sx={{ height: `${rowHeight}`, maxHeight: `${rowHeight}` }}>
                    <RightBorderCell component="th" scope="row" sx={{ height: 80 }}>
                      <Box display="flex" flexDirection="row" wrap="noWrap" gap={2} justify="center">
                        <HomeIcon></HomeIcon>
                        <Box display="flex" flexDirection="column" justifyContent="space-between">
                          <Typography noWrap={true} variant="body1" color="inherit">
                            {row?.first_name + ' ' + row?.last_name}
                          </Typography>
                          <Typography noWrap={true} variant="body2" color="inherit">
                            {row?.amount + ' KM'}
                          </Typography>
                          <Typography noWrap={true} variant="body2" sx={{ color: `${theme.palette.primary.dark}` }}>
                            WZ-{row?.id}
                            {row?.erp_key ? `#${row?.erp_key}` : ''}
                          </Typography>
                        </Box>
                      </Box>
                    </RightBorderCell>
                    <TableCell sx={{ height: `${rowHeight}`, maxHeight: `${rowHeight}` }}>
                      <StatusBox statusColor={statusColor(row?.status_code)} statusName={row?.status_name}></StatusBox>
                    </TableCell>
                    <TableCell sx={{ height: `${rowHeight}`, maxHeight: `${rowHeight}` }}>
                      <Box display="flex">
                        {row?.timeline.map((step, index) => (
                          <Tooltip
                            key={index}
                            title={`${step?.NAME}` + ' - ' + `${moment(step?.CREATED_AT).format('DD.MM.YYYY. kk:mm')}`}
                          >
                            <IconButton>
                              <BsFillSquareFill
                                size={20}
                                style={{
                                  margin: -5,
                                  marginTop: -6,
                                  color: `${step?.STATUS_TO >= 1 && step?.STATUS_TO <= 5 ? colorPhase1 : colorPhase2}`,
                                }}
                                fontSize="inherit"
                              />
                            </IconButton>
                          </Tooltip>
                        ))}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ height: `${rowHeight}`, maxHeight: `${rowHeight}` }}>
                      <Box color="#2B3033" display="flex" wrap="noWrap">
                        <BsClockHistory style={{ marginRight: '5px', marginTop: '3px', color: '#7D8081' }} />
                        <Typography noWrap={true}>{getTimeDiff(row?.timeline, 'klijent')}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ height: `${rowHeight}`, maxHeight: `${rowHeight}` }}>
                      <Box color="#2B3033" display="flex" wrap="noWrap">
                        <BsClockHistory style={{ marginRight: '5px', marginTop: '3px', color: '#7D8081' }} />
                        <Typography noWrap={true}>{getTimeDiff(row?.timeline, 'radnik')}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ height: `${rowHeight}`, maxHeight: `${rowHeight}` }}>
                      <Typography noWrap={true} variant="body1" color="inherit">
                        {moment(row?.created_at).format('DD.MM.YYYY.')}
                      </Typography>
                    </TableCell>
                  </BottomBorderRow>
                ))}
                {emptyArray.map((_, i) => (
                  <TableRow key={i} />
                ))}
              </TableBody>
            )}
          </Table>
          <Pagination
            count={totalPages}
            size="large"
            page={page}
            onChange={(_, value) => setPage(value)}
            sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
          />
        </>
      )}
    </>
  );

  /*return (
    <>
    {error && <ServerError/>}
    {!error}   
    <Table sx={{ tbody: { height: `calc(${itemsPerPage} * ${rowHeight})` } }}>
    <TableHead>
      <TableRow>
        {headers.map(({ header, ...rest }) => (
          <TableCell key={header} sx={{ ...rest }}>
            <Typography variant="body1" color="inherit">
              {header}
            </Typography>
          </TableCell>
        ))}
        </TableRow>
    </TableHead>
    {(!error && (isLoading || isRefetching)) && 
      <TableBody>
        <TableRow sx={{ position: 'relative' }}>
          <TableCell>
            <Box position="absolute" left="50%" top="50%">
              <CircularProgress size={64} />
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>}               
    {!(error || isLoading || isRefetching) && 
      <TableBody>
        {items?.map((row) => (
          <BottomBorderRow key={row?.id} sx={{ height: `${rowHeight}`, maxHeight: `${rowHeight}` }}>
            <RightBorderCell component="th" scope="row" sx={{ height: 80}}>
              <Box display="flex" flexDirection="row" wrap="noWrap" gap={2} justify="center">
                <HomeIcon></HomeIcon>
                <Box display="flex" flexDirection="column" justifyContent="space-between">
                  <Typography noWrap={true} variant="body1" color="inherit">
                    {row?.first_name + ' ' + row?.last_name}
                  </Typography>
                  <Typography noWrap={true} variant="body2" color="inherit">
                    {row?.amount + ' KM'}
                  </Typography>
                </Box>
              </Box>
            </RightBorderCell>
            <TableCell sx={{ height: `${rowHeight}`, maxHeight: `${rowHeight}` }}>
              <StatusBox statusColor={statusColor(row?.status_code)} statusName={row?.status_name}></StatusBox>
            </TableCell>
            <TableCell sx={{ height: `${rowHeight}`, maxHeight: `${rowHeight}` }}>
              <Box display="flex">
                {row?.timeline.map((step, index) => (
                  <Tooltip
                    key={index}
                    title={`${step?.NAME}` + ' - ' + `${moment(step?.CREATED_AT).format('DD.MM.YYYY. kk:mm')}`}
                  >
                    <IconButton>
                      <BsFillSquareFill
                        size={20}
                        style={{
                          margin: -5,
                          marginTop: -6,
                          color: `${step?.STATUS_TO >= 1 && step?.STATUS_TO <= 5 ? colorPhase1 : colorPhase2}`,
                        }}
                        fontSize="inherit"
                      />
                    </IconButton>
                  </Tooltip>
                ))}
              </Box>
            </TableCell>
            <TableCell sx={{ height: `${rowHeight}`, maxHeight: `${rowHeight}` }}>
              <Box color="#2B3033" display="flex" wrap="noWrap">
                <BsClockHistory style={{ marginRight: '5px', marginTop: '3px', color: '#7D8081' }} />
                <Typography noWrap={true}>{getTimeDiff(row?.timeline, 'klijent')}</Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ height: `${rowHeight}`, maxHeight: `${rowHeight}` }}>
              <Box color="#2B3033" display="flex" wrap="noWrap">
                <BsClockHistory style={{ marginRight: '5px', marginTop: '3px', color: '#7D8081' }} />
                <Typography noWrap={true}>{getTimeDiff(row?.timeline, 'radnik')}</Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ height: `${rowHeight}`, maxHeight: `${rowHeight}` }}>
              <Typography noWrap={true} variant="body1" color="inherit">
                {moment(row?.created_at).format('DD.MM.YYYY.')}
              </Typography>
            </TableCell>
          </BottomBorderRow>
        ))}
        {emptyArray.map((_, i) => (
          <TableRow key={i} />
        ))}
    </TableBody>
  }
  </Table>
  <Pagination
      count={totalPages}
      size="large"
      page={page}
      onChange={(_, value) => setPage(value)}
      sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
    />
  </>
  );*/
}
